/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI167D
 * 화면설명: 결과보기(스마트 리포트) > 연금액
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap">
      <!-- 상단 START -->
      <ur-box-container v-if="!lv_PowerImediatly && lv_Filters.length > 0" alignV="start" componentid="" direction="column" class="ns-add-area con-area mt30">   
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="lv_Filter" @input="fn_SegmentBoxClicked">
            <mo-segment-button v-for="(item, idx) in lv_Filters" :value="item.index" :key="idx">{{item.label}}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pa2024 pt10"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="lv_HeaderItem" class="chip-type-wrap chip-ty1" @input="fn_SegmentBoxClicked2">
            <template v-if="lv_PowerImediatly">
              <mo-segment-button v-for="(item, idx) in lv_RightTitles" :value="item.key" :key="idx">{{item.title}}</mo-segment-button>
            </template>
            <template v-else>
              <mo-segment-button v-if="lv_StrIrtTypCd==='04'" v-for="(item, idx) in lv_HeaderItems" :value="item.key" :key="idx">{{item.title}}</mo-segment-button>
              <mo-segment-button v-if="lv_StrIrtTypCd!=='04'" v-for="(item, idx) in lv_HeaderItemsSub" :value="item.key" :key="idx">{{item.title}}</mo-segment-button>
            </template>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt20">
        <ur-box-container alignV="start" componentid="" direction="column" class="round-box bgcolor-blue">
          <div class="full fexTy3">
            <span class="fs14rem crTy-bk7">연금지급 개시시점</span>
            <b class="fs17rem txt-right">{{ parentParam.anutBgnAge }}세</b>
          </div>
          <div class="full fexTy3 mt10" v-if="!lv_PowerImediatly">
            <span class="fs14rem crTy-bk7">계약자적립액</span>
            <b class="fs17rem txt-right" v-if="lv_StrIrtTypCd === '04'">{{ lv_HeaderItems[+lv_HeaderItem-1].anutPymBgnPntmAcumAmt }}</b>
            <b class="fs17rem txt-right" v-if="lv_StrIrtTypCd !== '04'">{{ lv_HeaderItemsSub[+lv_HeaderItem-1].anutPymBgnPntmAcumAmt }}</b>
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 상단 END -->
      <!-- List START -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20">
        <div class="table-area">
          <table class="table row-type chart-type">
            <colgroup>
              <col width="29%" />
              <col width="26%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>연금유형</th>
                <th>지급형태</th>
                <th>연금액({{lv_Unit}})</th>
              </tr>
            </thead>
            <!-- 파워즉시연금 경우 START-->
            <tbody v-if="lv_PowerImediatly">
              <tr v-for="(item, idx) in lv_Items" :key="idx">
                <td class="txt-center pl9 pr9">{{ item.arg1 }}</td>
                <td class="txt-center">{{ item.arg2 }}</td>
                <td class="pr10 txt-right">
                  <template v-if="lv_HeaderItem === '0'">
                    <div class="bar-chart">
                      <div class="fill-bar" :style="{width: fn_GetWidth(item.arg3)+'%'}"></div><!--:style="width:"-->
                    </div>
                    <span class="count">{{ fn_NumberWithCommas(item.arg3 )}}</span>
                  </template>
                  <template v-else>
                    <div class="bar-chart">
                      <div class="fill-bar" :style="{width: fn_GetWidth(item.arg4)+'%'}"></div><!--:style="width:"-->
                    </div>
                    <span class="count">{{ fn_NumberWithCommas(item.arg4) }}</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- 파워즉시연금 경우 END-->
            <!-- 파워즉시연금 아닌 경우 START-->
            <tbody v-if="!lv_PowerImediatly">
              <tr v-for="(item, idx) in lv_Items" :key="idx">
                <td class="txt-center pl9 pr9" :rowspan="item.cnt" v-if="item.isFirst">{{item.arg1}}</td>
                <td class="txt-center">{{item.arg2}}</td>
                <td class="pr10 txt-right" v-for="(itemUnit, index) in item.arg4" :key="index">
                <!-- <td class="pr10 txt-right" v-for="(itemUnit, index) in item.arg3" :key="index"> -->
                <!-- <td class="pr10 txt-right" v-for="(itemUnit, index) in item.arg3[+lv_HeaderItem-1]" :key="index"> -->
                  <!-- 그래프 영역 -->
                  <div class="bar-chart">
                    <div class="fill-bar" :style="{width: fn_GetWidth(itemUnit)+'%'}"></div><!--:style="width:"-->
                  </div>
                  <!-- 숫자 -->
                  <span class="count">{{ fn_NumberWithCommas(itemUnit) }}</span>
                </td>
              </tr>
            </tbody>
            <!-- 파워즉시연금 아닌 경우 END-->
          </table>
        </div> 
      </ur-box-container>
      <!-- List END -->
    </ur-box-container>
  </ur-box-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {
   /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI167D', 
  screenId: 'MSPPI167D',
  props: {
    parentParam: Object
  }, // parents data
  data() {
    return {
      lv_ShowData: true,
      lv_Unit: '만원', // 원 또는 만원
      lv_MainTitle: '', // 오른쪽 상단에 올라갈 Title
      lv_Items: [], // Table Body 에 binding할 list

      // 파워즉시 연금이 아닌 경우 필요한 변수
      lv_StrIrtTypCd: '', // 수익코드
      lv_HeaderItems: [],
      lv_HeaderItemsSub: [],
      lv_HeaderItem: '1', // 두번째 세그먼트 선택 변수
      // lv_Filter: [],
      lv_Filter: '',
      lv_Filters: [],
      lv_ItemsSeperate: [], // 전역변수용

      // 파워즉시 연금일 경우 필요한 변수
      lv_PowerImediatly: true, // 파워즉시연금인 경우
      lv_RightTitles: [],

      lv_MaxValue: 0 // 그래프의 max 값을 설정하기 위한 변수
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    if (this.parentParam !== null) {
      console.log('BindData_MSPPI167D')
      this.fn_MakeGrid(this.parentParam)
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_SegmentBoxClicked (item) {
      // console.log('fn_SegmentBoxClicked ===> ', item)
      this.lv_Items = this.lv_ItemsSeperate[this.lv_Filter-1]
      this.fn_SegmentBoxClicked2(this.lv_HeaderItem)
      // this.lv_Items = this.lv_ItemsSeperate[this.lv_Filter[0].key]
      if (this.$refs.table !== undefined) this.$refs.table.scrollTop = 0
    },
    fn_SegmentBoxClicked2 (item) {
      // console.log('fn_SegmentBoxClicked2 ==> ', item)
      if (!this.lv_PowerImediatly) {
        this.lv_Items.forEach(filter => {
          filter.arg4 = [filter.arg3[this.lv_HeaderItem-1]]
        })
      } else {
        // this.lv_Items.forEach(filter => {
        //   if (item === '0') {
        //     filter.arg3 = [filter.arg3]
        //   } else {
        //     filter.arg4 = [filter.arg4]
        //   }
        // })
      }
      // console.log('fn_SegmentBoxClicked2 ===> ', item)
    },
    fn_NumberWithCommas (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    fn_GetWidth (v) {
      // console.log('fn_GetWidth ===> ', 100 * (v / (this.lv_MaxValue)))
      return 100 * v / (this.lv_MaxValue)
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentPramObj) {
      let parentPram = parentPramObj
      // let lv_MainTitle = '' // 왼쪽   대타이틀
      let contrAcumAmt = [] // 오른쪽 대타이틀[i] - 변액상품(투자수익형인 경우)
      let rightTitle = [] // 오른쪽 소타이틀[i]
      let ZAnutRcvIrtDto = [] // 기초연금     Main Data
      let wlifeAnutAm = [] // 종신연금형 추가여부
      let wlifePlusAm1 = [] // 종신연금플러스형 추가여부
      let wlifePlusAm2 = [] // 종신연금플러스형 추가여부
      let wlifePlusAm3 = [] // 종신연금플러스형 추가여부
      let dcdAnutAm = [] // 확정기간형 추가여부

      let colCount = 0 // colCount = 연금지급 출력 리스트 갯수(세로)

      let tot_wlifeAnutAmt10 = 0
      let tot_wlifeAnutAmt20 = 0
      let tot_wlifeAnutAmt30 = 0
      let tot_wlifeAnutAmt00 = 0
      let tot_dcdAnutAmt05 = 0
      let tot_dcdAnutAmt10 = 0
      let tot_dcdAnutAmt15 = 0
      let tot_dcdAnutAmt20 = 0
      let tot_dcdAnutAmt30 = 0
      let tot_survIhrtAnutAmt = 0
      let tot_dthLamntIhrtAnutAmt = 0
      // TODO 상속연금형(사망보장형)추가
      let tot_survIhrtDthCovrAnutAmt = 0
      let tot_dthLamntIhrtDthCovrAnutAmt = 0
      let tot_wlifePlusAmt10 = 0
      let tot_wlifePlusAmt20 = 0
      let tot_wlifePlusAmt30 = 0
      let tot_wlifePlusAmt00 = 0
      let tot_dcdPlusAmt05 = 0
      let tot_dcdPlusAmt10 = 0
      let tot_dcdPlusAmt15 = 0
      let tot_dcdPlusAmt20 = 0
      let tot_dcdPlusAmt30 = 0
      let tot_blendDcdAnpsn = 0
      // let tot_blendDcdAnpsn = 0
      let tot_blendWlifeAnpsn = 0
      let tot_wlifeAnutAm = 0
      let tot_dcdAnutAm = 0
      let tot_wlifePlusAm1 = 0
      let tot_wlifePlusAm2 = 0
      let tot_wlifePlusAm3 = 0

      // 파워즉시 연금인 경우
      if (piCommonUtil.isObj(parentPram.powerIanutTotRcvExptAmt) && parentPram.powerIanutTotRcvExptAmt.length > 0) {
        this.fn_PowerImediatlyCase(parentPram)
      // 파워즉시 연금이 아닌 경우
      } else if (piCommonUtil.isObj(parentPram.anut) && parentPram.anut.length > 0) {
        this.lv_PowerImediatly = false
        // to-be Add ==============
        let chkCnt = 1
        // ==============
        for (let i = 0; i < parentPram.anut.length; i++) {
          if (parentPram.anut[i].diseSurgOccuYn !== 'N' || parentPram.anut[i].lvngFdRcvYn !== 'N') {
            continue
          }

          let anutPymBgnPntmAcumAmt = piCommonUtil.toChange(parentPram.anut[i].anutPymBgnPntmAcumAmt, 'number')// 원금액처리 연금개시시점계약자적립금[i]
          // let anutPymBgnPntmAcumAmt = this.toNumber(parentPram.anut[i].anutPymBgnPntmAcumAmt)// 원금액처리 연금개시시점계약자적립금[i]
          let strIrtTypCd = parentPram.anut[i].irtTypCd // 이율유형코드(04:투자수익형, 외:금리연동형???)
          this.lv_StrIrtTypCd = strIrtTypCd

          // 첫번째 세그먼트 구성 ===========
          // this.lv_MainTitle = '(' + parentPram.anutBgnAge + ')세 연금지급 개시시점의 연금계약 계약자적립액'
          if (strIrtTypCd === '04') { // 이율유형코드 === 투자수익형
            // contrAcumAmt.push('투자수익률 ' + piCommonUtil.toChange(parentPram.anut[i].lvyIrt, 'percent', 3) + '% 가정 (' + this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt) + '원)')
            contrAcumAmt.push({ key: chkCnt + '',
                                title: `${piCommonUtil.toChange(parentPram.anut[i].lvyIrt, 'percent', 3)}% 가정`,
                                anutPymBgnPntmAcumAmt: `${this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt)}원`})
            // contrAcumAmt.push('투자수익률 ' + this.toPercent(parentPram.anut[i].lvyIrt, 3) + '% 가정 (' + this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt) + '원)')
            // this.lv_MainTitle = '(' + parentPram.anutBgnAge + ')세 연금지급 개시시점의 계약자적립액'
            chkCnt ++
          }
          // 연금예시 1차 가공 =================
          if (parentPram.anut[i].anutRcvIrt.length > 0) {
            for (let j = 0; j < parentPram.anut[i].anutRcvIrt.length; j++) {
              let objAnutRcvIrt = parentPram.anut[i].anutRcvIrt[j] // 이율유형코드B[colCount]
              // console.log(`1차 연금 가공 : ${j} - ${objAnutRcvIrt}`)
              let perLlvyIrt = piCommonUtil.toChange(objAnutRcvIrt.lvyIrt, 'percent', 2) // 연금지급개시전부리이율B[colCount]
              // let perLlvyIrt = this.toPercent(objAnutRcvIrt.lvyIrt, 2) // 연금지급개시전부리이율B[colCount]
              let addTitleObj = {key: '', title: '', anutPymBgnPntmAcumAmt: ''} // lv_HeaderItemsSub 에 담을 Object
              // min.ko ASR201200852 (변경) 사랑온 가입설계 결과보기 내 연금액 예시 로직 변경
              if (strIrtTypCd === '04') {
                if (objAnutRcvIrt.irtTypCd === '02') {
                  addTitleObj.key = colCount + 1 + ''
                  // addTitleObj.key = chkCnt2 + ''
                  addTitleObj.title = '연복리 ' + perLlvyIrt + '% 가정'
                  // rightTitle[colCount] = '연복리 ' + perLlvyIrt + '% 가정'
                } else if (objAnutRcvIrt.irtTypCd === '03') {
                  addTitleObj.key = colCount + 1 + ''
                  // addTitleObj.key = chkCnt2 + ''
                  addTitleObj.title = '최저보증이율 가정'
                  // rightTitle[colCount] = '최저보증이율 가정'
                } else {
                  continue
                }
              } else {
                if (strIrtTypCd === objAnutRcvIrt.irtTypCd) {
                  if (objAnutRcvIrt.irtTypCd === '02') {
                    addTitleObj.key = colCount + 1 + ''
                    // addTitleObj.key = chkCnt2 + ''
                    addTitleObj.title = '연복리 ' + perLlvyIrt + '% 가정'
                    // rightTitle[colCount] = '연복리 ' + perLlvyIrt + '% 가정'
                  } else if (objAnutRcvIrt.irtTypCd === '03') {
                    addTitleObj.key = colCount + 1 + ''
                    // addTitleObj.key = chkCnt2 + ''
                    addTitleObj.title = '최저보증이율 가정'
                    // rightTitle[colCount] = '최저보증이율 가정'
                  } else {
                    continue
                  }
                } else {
                  continue
                }
              }
              // strIrtTypCd => 02, 03, 04인 경우에만
              if (strIrtTypCd !== '04') {
                // as-is
                // rightTitle[colCount] += '(' + this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt) + '원)'
                // to-be
                addTitleObj.anutPymBgnPntmAcumAmt = `${this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt)}원`
                // rightTitle[colCount] += '(' + this.fn_NumberWithCommas(anutPymBgnPntmAcumAmt) + '원)'
              }
              
              // lv_HeaderItemsSub setting
              // rightTitle[colCount].push(addTitleObj)
              rightTitle[colCount] = addTitleObj
              // rowData
              let rowData = {}
              // 종신연금형
              rowData.wlifeAnutAmt10 = piCommonUtil.toChange(objAnutRcvIrt.wlifeAnutAmt10, 'unit') // 10회보증
              rowData.wlifeAnutAmt20 = piCommonUtil.toChange(objAnutRcvIrt.wlifeAnutAmt20, 'unit') // 20회보증
              rowData.wlifeAnutAmt30 = piCommonUtil.toChange(objAnutRcvIrt.wlifeAnutAmt30, 'unit') // 30회보증
              rowData.wlifeAnutAmt00 = piCommonUtil.toChange(objAnutRcvIrt.wlifeAnutAmt00, 'unit') // 100세보증
              // 확정기간연금형
              rowData.dcdAnutAmt05 = piCommonUtil.toChange(objAnutRcvIrt.dcdAnutAmt05, 'unit') // 5년형
              rowData.dcdAnutAmt10 = piCommonUtil.toChange(objAnutRcvIrt.dcdAnutAmt10, 'unit') // 10년형
              rowData.dcdAnutAmt15 = piCommonUtil.toChange(objAnutRcvIrt.dcdAnutAmt15, 'unit') // 15년형
              rowData.dcdAnutAmt20 = piCommonUtil.toChange(objAnutRcvIrt.dcdAnutAmt20, 'unit') // 20년형
              rowData.dcdAnutAmt30 = piCommonUtil.toChange(objAnutRcvIrt.dcdAnutAmt30, 'unit') // 30년형
              // 상속연금형
              rowData.survIhrtAnutAmt = piCommonUtil.toChange(objAnutRcvIrt.survIhrtAnutAmt, 'unit') // 생존시
              rowData.dthLamntIhrtAnutAmt = piCommonUtil.toChange(objAnutRcvIrt.dthLamntIhrtAnutAmt, 'unit') // 해지 or 사망시 일시금
              // rowData.survIhrtAnutAmt = this.toManWon(objAnutRcvIrt.survIhrtAnutAmt) // 생존시
              // rowData.dthLamntIhrtAnutAmt = this.toManWon(objAnutRcvIrt.dthLamntIhrtAnutAmt) // 해지 or 사망시 일시금
              // TODO 상속연금형(사망보장형)추가 
              rowData.survIhrtDthCovrAnutAmt = piCommonUtil.toChange(objAnutRcvIrt.survIhrtDthCovrAnutAmt, 'unit') // 생존시
              rowData.dthLamntIhrtDthCovrAnutAmt = piCommonUtil.toChange(objAnutRcvIrt.dthLamntIhrtDthCovrAnutAmt, 'unit') // 사망시 일시금
              // 종신연금플러스형
              rowData.wlifePlusAmt10 = piCommonUtil.toChange(objAnutRcvIrt.wlifePlusAmt10, 'unit') // 10회보증
              rowData.wlifePlusAmt20 = piCommonUtil.toChange(objAnutRcvIrt.wlifePlusAmt20, 'unit') // 20회보증
              rowData.wlifePlusAmt30 = piCommonUtil.toChange(objAnutRcvIrt.wlifePlusAmt30, 'unit') // 30회보증
              rowData.wlifePlusAmt00 = piCommonUtil.toChange(objAnutRcvIrt.wlifePlusAmt00, 'unit') // 100세보증

              if ((objAnutRcvIrt.ilstTypClbyAnutProd !== undefined) && objAnutRcvIrt.ilstTypClbyAnutProd.length > 0) {
                let objIlstTypClbyAnutProd = objAnutRcvIrt.ilstTypClbyAnutProd

                for (let k = 0; k < objIlstTypClbyAnutProd.length; k++) {
                  let record = {}
                  let strAnutPymTypCd = objIlstTypClbyAnutProd[k].anutPymTypCd
                  let strHptsAnutIlstTypCd = objIlstTypClbyAnutProd[k].hptsAnutIlstTypCd
                  let strAnutPymPrd = objIlstTypClbyAnutProd[k].anutPymPrd
                  let manAnutAmt = piCommonUtil.toChange(objIlstTypClbyAnutProd[k].anutAmt, 'unit')
                  // let manAnutAmt = this.toManWon(objIlstTypClbyAnutProd[k].anutAmt)
                  record.anutPymPrd = strAnutPymPrd
                  record.anutAmt = manAnutAmt

                  if (strAnutPymTypCd === '01' && strHptsAnutIlstTypCd === '01') { // 종신형(실제선택한보증기간)
                    wlifeAnutAm.push(record)
                  } else if (strAnutPymTypCd === '05' && strHptsAnutIlstTypCd === '02') { // 종신플러스형(min(10,기대여명))
                    wlifePlusAm1.push(record)
                  } else if (strAnutPymTypCd === '05' && strHptsAnutIlstTypCd === '03') { // 종신플러스형(기대여명)
                    wlifePlusAm2.push(record)
                  } else if (strAnutPymTypCd === '05' && strHptsAnutIlstTypCd === '04') { // 종신플러스형(min(30,100-연금지급개시나이))
                    wlifePlusAm3.push(record)
                  } else if (strAnutPymTypCd === '02' && strHptsAnutIlstTypCd === '01') { // 확정기간형(실제선택한보증기간)
                    dcdAnutAm.push(record)
                  }
                }
              }
              // 확정기간연금플러스형
              rowData.dcdPlusAmt05 = piCommonUtil.toChange(objAnutRcvIrt.dcdPlusAmt05, 'unit') // 5년형
              rowData.dcdPlusAmt10 = piCommonUtil.toChange(objAnutRcvIrt.dcdPlusAmt10, 'unit') // 10년형
              rowData.dcdPlusAmt15 = piCommonUtil.toChange(objAnutRcvIrt.dcdPlusAmt15, 'unit') // 15년형
              rowData.dcdPlusAmt20 = piCommonUtil.toChange(objAnutRcvIrt.dcdPlusAmt20, 'unit') // 20년형
              rowData.dcdPlusAmt30 = piCommonUtil.toChange(objAnutRcvIrt.dcdPlusAmt30, 'unit') // 30년형
              // 확정기간연금플러스형(50%) + 종신연금플러스형(50%)
              rowData.blendDcdAnpsn = piCommonUtil.toChange(objAnutRcvIrt.blendDcdAnpsn, 'unit') // 확정기간 20년
              rowData.blendWlifeAnpsn = piCommonUtil.toChange(objAnutRcvIrt.blendWlifeAnpsn, 'unit') // 종신 10회보증

              colCount++
              ZAnutRcvIrtDto.push(rowData)
            }
          }
        }
        // console.log(ZAnutRcvIrtDto, '연금예시 - ZAnutRcvIrtDto(1차 가공 데이터)')

        if (ZAnutRcvIrtDto.length > 0) {
          // colCount = 연금지급 출력 리스트 갯수(세로)
          // parseFloat 을 할 필요가 없음. 할 필요가 생기면 추가
          // 값 += parseFloat(데이터.replace(/,/gi, ''))
          for (let obj in ZAnutRcvIrtDto) {
            tot_wlifeAnutAmt10 += ZAnutRcvIrtDto[obj].wlifeAnutAmt10
            tot_wlifeAnutAmt20 += ZAnutRcvIrtDto[obj].wlifeAnutAmt20
            tot_wlifeAnutAmt30 += ZAnutRcvIrtDto[obj].wlifeAnutAmt30
            tot_wlifeAnutAmt00 += ZAnutRcvIrtDto[obj].wlifeAnutAmt00
            tot_dcdAnutAmt05 += ZAnutRcvIrtDto[obj].dcdAnutAmt05
            tot_dcdAnutAmt10 += ZAnutRcvIrtDto[obj].dcdAnutAmt10
            tot_dcdAnutAmt15 += ZAnutRcvIrtDto[obj].dcdAnutAmt15
            tot_dcdAnutAmt20 += ZAnutRcvIrtDto[obj].dcdAnutAmt20
            tot_dcdAnutAmt30 += ZAnutRcvIrtDto[obj].dcdAnutAmt30
            tot_survIhrtAnutAmt += ZAnutRcvIrtDto[obj].survIhrtAnutAmt
            tot_dthLamntIhrtAnutAmt += ZAnutRcvIrtDto[obj].dthLamntIhrtAnutAmt
            // TODO 상속연금형(사망보장형)추가 
            tot_survIhrtDthCovrAnutAmt += ZAnutRcvIrtDto[obj].survIhrtDthCovrAnutAmt
            tot_dthLamntIhrtDthCovrAnutAmt += ZAnutRcvIrtDto[obj].dthLamntIhrtDthCovrAnutAmt
            tot_wlifePlusAmt10 += ZAnutRcvIrtDto[obj].wlifePlusAmt10
            tot_wlifePlusAmt20 += ZAnutRcvIrtDto[obj].wlifePlusAmt20
            tot_wlifePlusAmt30 += ZAnutRcvIrtDto[obj].wlifePlusAmt30
            tot_wlifePlusAmt00 += ZAnutRcvIrtDto[obj].wlifePlusAmt00
            tot_dcdPlusAmt05 += ZAnutRcvIrtDto[obj].dcdPlusAmt05
            tot_dcdPlusAmt10 += ZAnutRcvIrtDto[obj].dcdPlusAmt10
            tot_dcdPlusAmt15 += ZAnutRcvIrtDto[obj].dcdPlusAmt15
            tot_dcdPlusAmt20 += ZAnutRcvIrtDto[obj].dcdPlusAmt20
            tot_dcdPlusAmt30 += ZAnutRcvIrtDto[obj].dcdPlusAmt30
            tot_blendDcdAnpsn += ZAnutRcvIrtDto[obj].blendDcdAnpsn
            tot_blendWlifeAnpsn += ZAnutRcvIrtDto[obj].blendWlifeAnpsn
          }
          for (let obj in wlifeAnutAm) {
            tot_wlifeAnutAm += wlifeAnutAm[obj].anutAmt
          }
          for (let obj in dcdAnutAm) {
            tot_dcdAnutAm += dcdAnutAm[obj].anutAmt
          }
          for (let obj in wlifePlusAm1) {
            tot_wlifePlusAm1 += wlifePlusAm1[obj].anutAmt
          }
          for (let obj in wlifePlusAm2) {
            tot_wlifePlusAm2 += wlifePlusAm2[obj].anutAmt
          }
          for (let obj in wlifePlusAm3) {
            tot_wlifePlusAm3 += wlifePlusAm3[obj].anutAmt
          }
          let rowCount = 0
          if (tot_wlifeAnutAmt10 > 0) rowCount++
          if (tot_wlifeAnutAmt20 > 0) rowCount++
          if (tot_wlifeAnutAmt30 > 0) rowCount++
          if (tot_wlifeAnutAmt00 > 0) rowCount++
          if (tot_dcdAnutAmt05 > 0) rowCount++
          if (tot_dcdAnutAmt10 > 0) rowCount++
          if (tot_dcdAnutAmt15 > 0) rowCount++
          if (tot_dcdAnutAmt20 > 0) rowCount++
          if (tot_dcdAnutAmt30 > 0) rowCount++
          if (tot_survIhrtAnutAmt > 0) rowCount++
          if (tot_dthLamntIhrtAnutAmt > 0) rowCount++
          // TODO 상속연금형(사망보장형)추가
          if (tot_survIhrtDthCovrAnutAmt > 0) rowCount++
          if (tot_dthLamntIhrtDthCovrAnutAmt > 0) rowCount++
          if (tot_wlifePlusAmt10 > 0) rowCount++
          if (tot_wlifePlusAmt20 > 0) rowCount++
          if (tot_wlifePlusAmt30 > 0) rowCount++
          if (tot_wlifePlusAmt00 > 0) rowCount++
          if (tot_dcdPlusAmt05 > 0) rowCount++
          if (tot_dcdPlusAmt10 > 0) rowCount++
          if (tot_dcdPlusAmt15 > 0) rowCount++
          if (tot_dcdPlusAmt20 > 0) rowCount++
          if (tot_dcdPlusAmt30 > 0) rowCount++
          if (tot_blendDcdAnpsn > 0) rowCount++
          if (tot_blendDcdAnpsn > 0) rowCount++
          if (tot_blendWlifeAnpsn > 0) rowCount++
          if (tot_wlifeAnutAm > 0) rowCount++
          if (tot_dcdAnutAm > 0) rowCount++
          if (tot_wlifePlusAm1 > 0) rowCount++
          if (tot_wlifePlusAm2 > 0) rowCount++
          if (tot_wlifePlusAm3 > 0) rowCount++
          let rows = []
          for (let i = 0; i < rowCount; i++) {
            let row = {}
            rows.push(row)
          }
          this.gridData4 = rows
          // let gridData = this.gridData4

          let rowNum = 0
          // let colNo = 1
          let AnutNm = ''

          // 기존 ep 소스에서 좀 더 효율적으로 변경
          let mapppingInfo = {
            tot_wlifeAnutAmt10: {arg1: '종신연금형', arg2: '10회 보증', value: tot_wlifeAnutAmt10},
            tot_wlifeAnutAmt20: {arg1: '종신연금형', arg2: '20회 보증', value: tot_wlifeAnutAmt20},
            tot_wlifeAnutAmt30: {arg1: '종신연금형', arg2: '30회 보증', value: tot_wlifeAnutAmt30},
            tot_wlifeAnutAmt00: {arg1: '종신연금형', arg2: '100세 보증', value: tot_wlifeAnutAmt00},
            tot_wlifeAnutAm: {arg1: '종신연금형', arg2: '회 보증', value: tot_wlifeAnutAm},
            tot_dcdAnutAmt05: {arg1: '확정기간연금형', arg2: '5년형', value: tot_dcdAnutAmt05},
            tot_dcdAnutAmt10: {arg1: '확정기간연금형', arg2: '10년형', value: tot_dcdAnutAmt10},
            tot_dcdAnutAmt15: {arg1: '확정기간연금형', arg2: '15년형', value: tot_dcdAnutAmt15},
            tot_dcdAnutAmt20: {arg1: '확정기간연금형', arg2: '20년형', value: tot_dcdAnutAmt20},
            tot_dcdAnutAmt30: {arg1: '확정기간연금형', arg2: '30년형', value: tot_dcdAnutAmt30},
            tot_dcdAnutAm: {arg1: '확장기간형', arg2: '년형', value: tot_dcdAnutAm},
            tot_survIhrtAnutAmt: {arg1: '상속연금형', arg2: '생존시', value: tot_survIhrtAnutAmt},
            tot_dthLamntIhrtAnutAmt: {arg1: '상속연금형', arg2: '해지 or 사망시 일시금', value: tot_dthLamntIhrtAnutAmt},
            // TODO 상속연금형(사망보장형)추가 
            tot_survIhrtDthCovrAnutAmt: {arg1: '상속연금형(사망보장형)', arg2: '생존시', value: tot_survIhrtDthCovrAnutAmt},
            tot_dthLamntIhrtDthCovrAnutAmt: {arg1: '상속연금형(사망보장형)', arg2: '사망시 일시금', value: tot_dthLamntIhrtDthCovrAnutAmt},
            tot_wlifePlusAmt10: {arg1: '종신연금플러스형', arg2: '10회 보증', value: tot_wlifePlusAmt10},
            tot_wlifePlusAmt20: {arg1: '종신연금플러스형', arg2: '20회 보증', value: tot_wlifePlusAmt20},
            tot_wlifePlusAmt30: {arg1: '종신연금플러스형', arg2: '30회 보증', value: tot_wlifePlusAmt30},
            tot_wlifePlusAmt00: {arg1: '종신연금플러스형', arg2: '100세 보증', value: tot_wlifePlusAmt00},
            tot_wlifePlusAm1: {arg1: '종신연금플러스형', arg2: '회 보증', value: tot_wlifePlusAm1},
            tot_wlifePlusAm2: {arg1: '종신연금플러스형', arg2: '회 보증', value: tot_wlifePlusAm2},
            tot_wlifePlusAm3: {arg1: '종신연금플러스형', arg2: '회 보증', value: tot_wlifePlusAm3},
            tot_dcdPlusAmt05: {arg1: '확정기간연금플러스형', arg2: '5년형', value: tot_dcdPlusAmt05},
            tot_dcdPlusAmt10: {arg1: '확정기간연금플러스형', arg2: '10년형', value: tot_dcdPlusAmt10},
            tot_dcdPlusAmt15: {arg1: '확정기간연금플러스형', arg2: '15년형', value: tot_dcdPlusAmt15},
            tot_dcdPlusAmt20: {arg1: '확정기간연금플러스형', arg2: '20년형', value: tot_dcdPlusAmt20},
            tot_dcdPlusAmt30: {arg1: '확정기간연금플러스형', arg2: '30년형', value: tot_dcdPlusAmt30},
            tot_blendDcdAnpsn: {arg1: '확정기간연금플러스형(50%) \n+ 종신연금플러스형(50%)', arg2: '확정기간 20년', value: tot_blendDcdAnpsn},
            tot_blendWlifeAnpsn: {arg1: '확정기간연금플러스형(50%) \n+ 종신연금플러스형(50%)', arg2: '종신 10회보증', value: tot_blendWlifeAnpsn}
          }

          for (let obj in mapppingInfo) {
            if (mapppingInfo[obj].value > 0) {
              if (String(obj) === 'tot_wlifeAnutAm') {
                rows[rowNum]['arg1'] = '종신연금형'
                if (wlifeAnutAm !== null && wlifeAnutAm.length > 0) {
                  rows[rowNum]['arg2'] = wlifeAnutAm[0].anutPymPrd + '회 보증'
                } else {
                  rows[rowNum]['arg2'] = ''
                }
                rows[rowNum]['arg3'] = []
                for (let obj in wlifeAnutAm) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(wlifeAnutAm[obj].anutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(wlifeAnutAm[obj].anutAmt)))
                }
              } else if (String(obj) === 'tot_dcdAnutAm') {
                rows[rowNum]['arg1'] = '확정기간형'
                if (wlifeAnutAm !== null && wlifeAnutAm.length > 0) {
                  rows[rowNum]['arg2'] = dcdAnutAm[0].anutPymPrd + '년형'
                } else {
                  rows[rowNum]['arg2'] = ''
                }
                rows[rowNum]['arg3'] = []
                for (let obj in dcdAnutAm) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(dcdAnutAm[obj].anutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(dcdAnutAm[obj].anutAmt)))
                }
              } else if (String(obj) === 'tot_survIhrtAnutAmt') {
                if (parentPram.IhrtAnutPlus === '1') {
                  AnutNm = '상속연금플러스형'
                } else {
                  AnutNm = '상속연금형'
                }
                rows[rowNum]['arg1'] = AnutNm
                rows[rowNum]['arg2'] = '생존시'
                rows[rowNum]['arg3'] = []
                for (let obj in ZAnutRcvIrtDto) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(ZAnutRcvIrtDto[obj].survIhrtAnutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(ZAnutRcvIrtDto[obj].survIhrtAnutAmt)))
                }
              } else if (String(obj) === 'tot_dthLamntIhrtAnutAmt') {
                if (parentPram.IhrtAnutPlus === '1') {
                  AnutNm = '상속연금플러스형'
                } else {
                  AnutNm = '상속연금형'
                }
                rows[rowNum]['arg1'] = AnutNm
                rows[rowNum]['arg2'] = '해지 or 사망시 일시금'
                rows[rowNum]['arg3'] = []
                for (let obj in ZAnutRcvIrtDto) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(ZAnutRcvIrtDto[obj].dthLamntIhrtAnutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(ZAnutRcvIrtDto[obj].dthLamntIhrtAnutAmt)))
                }
                // TODO 상속연금형(사망보장형)추가 
              } else if (String(obj) === 'tot_survIhrtDthCovrAnutAmt') {
                rows[rowNum]['arg1'] = '상속연금형(사망보장형)'
                rows[rowNum]['arg2'] = '생존시'
                rows[rowNum]['arg3'] = []
                for (let obj in ZAnutRcvIrtDto) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(ZAnutRcvIrtDto[obj].survIhrtDthCovrAnutAmt, 'number')))
                }
              } else if (String(obj) === 'tot_dthLamntIhrtDthCovrAnutAmt') {
                rows[rowNum]['arg1'] = '상속연금형(사망보장형)'
                rows[rowNum]['arg2'] = '사망시 일시금'
                rows[rowNum]['arg3'] = []
                for (let obj in ZAnutRcvIrtDto) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(ZAnutRcvIrtDto[obj].dthLamntIhrtDthCovrAnutAmt, 'number')))
                }
              } else if (String(obj) === 'tot_wlifePlusAm1') {
                rows[rowNum]['arg1'] = '종신연금플러스형'
                if (wlifeAnutAm !== null && wlifeAnutAm.length > 0) {
                  rows[rowNum]['arg2'] = wlifePlusAm1[0].anutPymPrd + '회 보증'
                } else {
                  rows[rowNum]['arg2'] = ''
                }
                rows[rowNum]['arg3'] = []
                for (let obj in wlifePlusAm1) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(wlifePlusAm1[obj].anutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(wlifePlusAm1[obj].anutAmt)))
                }
              } else if (String(obj) === 'tot_wlifePlusAm2') {
                rows[rowNum]['arg1'] = '종신연금플러스형'
                if (wlifeAnutAm !== null && wlifeAnutAm.length > 0) {
                  rows[rowNum]['arg2'] = wlifePlusAm2[0].anutPymPrd + '회 보증'
                } else {
                  rows[rowNum]['arg2'] = ''
                }
                rows[rowNum]['arg3'] = []
                for (let obj in wlifePlusAm2) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(wlifePlusAm2[obj].anutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(wlifePlusAm2[obj].anutAmt)))
                }
              } else if (String(obj) === 'tot_wlifePlusAm3') {
                rows[rowNum]['arg1'] = '종신연금플러스형'
                if (wlifeAnutAm !== null && wlifeAnutAm.length > 0) {
                  rows[rowNum]['arg2'] = wlifePlusAm3[0].anutPymPrd + '회 보증'
                } else {
                  rows[rowNum]['arg2'] = ''
                }
                rows[rowNum]['arg3'] = []
                for (let obj in wlifePlusAm3) {
                  rows[rowNum]['arg3'].push(piCommonUtil.toChange(piCommonUtil.toChange(wlifePlusAm3[obj].anutAmt, 'number')))
                  // rows[rowNum]['arg3'].push(this.toUnitAdd(this.toNumber(wlifePlusAm3[obj].anutAmt)))
                }
              } else {
                rows[rowNum]['arg1'] = mapppingInfo[obj].arg1
                rows[rowNum]['arg2'] = mapppingInfo[obj].arg2
                rows[rowNum]['arg3'] = []
                let objNm = String(obj).slice(4)
                for (let objInner in ZAnutRcvIrtDto) {
                  rows[rowNum]['arg3'].push((ZAnutRcvIrtDto[objInner][objNm]))
                  if (String(obj) !== 'tot_anutPymBgnPntmAcumAmt') {
                    this.lv_MaxValue = Math.max(this.lv_MaxValue, ZAnutRcvIrtDto[objInner][objNm])
                  }
                }
              }
              rowNum++
            }
            // 맨 앞의 분류 구분을 위한 First 설정
            let diffTitle = ''
            for (let i = 0; i < rows.length; ++i) {
              if (rows[i].arg1 !== diffTitle) {
                rows[i].isFirst = true
                diffTitle = rows[i].arg1
              } else {
                rows[i].isFirst = false
              }
            }
            // 맨 앞의 분류 구분을 위한 유형별 갯수 세기
            for (let i = 0; i < rows.length; ++i) {
              let cnt = 0
              if (rows[i].isFirst === true) {
                for (let j = i; j < rows.length; ++j) {
                  if (rows[i].arg1 === rows[j].arg1) cnt++
                }
                rows[i].cnt = cnt
              }
            }
          }

          // console.log('TYPE : ?', this.lv_StrIrtTypCd)
          // console.log(contrAcumAmt, '대타이틀')
          // console.log(rows, '연금예시 - rows(2차 가공데이터)')
          // console.log(rightTitle, '연금예시 - rightTitle')
          this.lv_HeaderItems = contrAcumAmt
          this.lv_HeaderItemsSub = rightTitle
          this.lv_Items = rows

          // lv_MaxValue 찾기
          this.lv_MaxValue = 0
          for (let i = 0; i < this.lv_Items.length; ++i) {
            for (let j = 0; j < this.lv_Items[i].arg3.length; ++j) {
              this.lv_MaxValue = Math.max(this.lv_MaxValue, this.lv_Items[i].arg3[j])
            }
          }

          let isfundingType = this.lv_StrIrtTypCd === '04'

          // 투자수익형일때
          if (isfundingType) {
            // 화면 구성이 달라짐으로 생기는 문제점 해결법 -> segmentBox 생성
            this.lv_Filters.length = 0
            let numSuppose = this.lv_HeaderItemsSub.length / this.lv_HeaderItems.length // 열 갯수 => segment 박스의 갯수
            for (let i = 0; i < numSuppose; ++i) {
              this.lv_Filters.push({
                key: i,
                index: rightTitle[i].key,
                // key: i, 
                label: rightTitle[i].title,
                anutPymBgnPntmAcumAmt: rightTitle[i].anutPymBgnPntmAcumAmt
              })
            }
            // for (let i = 0; i < numSuppose; ++i) {
            //   this.lv_Filters.push({
            //     key: i + '', 
            //     // key: i, 
            //     label: rightTitle[i]
            //   })
            // }
            // console.log(this.lv_Filters, ' <=== lv_Filters')

            this.lv_ItemsSeperate.legnth = 0
            let newArg3 = []
            for (let i = 0; i < numSuppose; ++i) {
            // 깊은 복사
              let items = this.$bizUtil.cloneDeep(this.lv_Items)
              // let items = JSON.parse(JSON.stringify(this.lv_Items))
              for (let k = 0; k < items.length; ++k) {
                let el = items[k]
                newArg3.length = 0 // 새로 할당할 arg3 list 초기화
                for (let j = i; j < el.arg3.length; j += numSuppose) {
                  newArg3.push(this.lv_Items[k].arg3[j])
                }
                el.arg3 = newArg3.slice()
              }
              this.lv_ItemsSeperate.push(items)
            }
            // this.lv_Filter.length = 0
            // this.lv_Filter.push(this.lv_Filters[0])
            // this.lv_Filter = this.lv_Filters[0].key
            this.lv_Filter = this.lv_Filters[0].index
            this.fn_SegmentBoxClicked(this.lv_Filter)
            if (this.lv_HeaderItems.length > 0 || this.lv_HeaderItemsSub.length > 0) {
              this.lv_HeaderItem = '1'
              this.fn_SegmentBoxClicked2(this.lv_HeaderItem)
            }
            // this.$nextTick(() => {
            //   if (this.lv_HeaderItems.length > 0) this.lv_HeaderItem = '0'
            //   this.fn_SegmentBoxClicked2(this.lv_HeaderItem)
            // })
          } else {
            if (this.lv_HeaderItemsSub.length > 0) {
              this.lv_HeaderItem = '1'
              this.fn_SegmentBoxClicked2(this.lv_HeaderItem)
            }
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_PowerImediatlyCase
    * 설명       : 파워즉시연금인 경우 bind Data
    ******************************************************************************/
    fn_PowerImediatlyCase (parentPram) {
      let rightTitle = [] // 오른쪽 소타이틀[i]
      let ZPowerIanutTotRcvExptAmtDto = [] // 파워즉시연금 Main Data
      let rows = []
      let rowData = {}
      let rowNum = 0
      let colNo = 0
      let colCount = 0 // colCount = 연금지급 출력 리스트 갯수(세로)

      this.lv_Unit = '원'
      this.lv_PowerImediatly = true

      let ZpowerIanutAnutAmtList = parentPram.powerIanutTotRcvExptAmt
      // console.log(parentPram.powerIanutTotRcvExptAmt, '*****연금예시 - parentPram.powerIanutTotRcvExptAmt(파워즉시 연금 데이터)')

      // 1. main title
      // this.lv_MainTitle = '(' + parentPram.anutBgnAge + ')세 연금지급 개시시점의 연금계약 적립액\n※ 연금월액 기준'
      this.lv_MainTitle = '연금지급 개시시점 : ' + parentPram.anutBgnAge + '세'

      // 2. right title / rowdata
      for (let i = 0; i < ZpowerIanutAnutAmtList.length; i++) {
        rowData = {}
        // 2.1. right title
        if (ZpowerIanutAnutAmtList[i].irtTypCd === '07') {
          continue
        } else if (ZpowerIanutAnutAmtList[i].irtTypCd === '02') { // 연복리 O.OO% 가정
          rightTitle[colCount] = {key: colCount + '', title: '연복리 ' + piCommonUtil.toChange(ZpowerIanutAnutAmtList[i].lvyIrt, 'percent', 2) + '% 가정'}
          // rightTitle[colCount] = '연복리 ' + this.toPercent(ZpowerIanutAnutAmtList[i].lvyIrt, 2) + '% 가정'
        } else if (ZpowerIanutAnutAmtList[i].irtTypCd === '03') { // 최저보증이율 가정
          rightTitle[colCount] = {key: colCount + '', title: '최저보증이율 가정'}
          // rightTitle[colCount] = '최저보증이율 가정'
        }
        this.lv_RightTitles = rightTitle
        // console.log(rightTitle, '*****파워즉시 연금예시 - rightTitle')

        // 2.2. rowdata 생성
        // 순수종신연금형(기본형)
        rowData.powerIanutMppsn = (ZpowerIanutAnutAmtList[i].powerIanutMppsn) // 파워즉시연금연금월액
        ZPowerIanutTotRcvExptAmtDto.push(rowData)
        colCount++
      }
      // console.log(ZPowerIanutTotRcvExptAmtDto, '*****파워즉시 연금예시 - ZPowerIanutTotRcvExptAmtDto(1차 가공 데이터)')

      // 3. 행 생성
      let row = {}
      rows.push(row)
      this.gridData4 = rows
      // let gridData = this.gridData4

      // 4. left title
      let zzanutPymCd = parentPram.zzanutPymCd // 연금지급유형
      let zzanutPymCdNm = '' // 연금지급유형코드명
      let zzantPymPrdVl = parentPram.zzantPymPrdVl // 연금지급기간

      switch (zzanutPymCd) {
        case '01':
          zzanutPymCdNm = '순수종신연금형\n(기본형)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '02':
          zzanutPymCdNm = '확정기간연금형'
          zzantPymPrdVl = zzantPymPrdVl + '년 만기'
          break
        case '03':
          zzanutPymCdNm = '상속연금형'
          zzantPymPrdVl = (zzantPymPrdVl === '999') ? '종신형' : zzantPymPrdVl + '년 만기'
          break
        case '04':
          zzanutPymCdNm = '순수종신연금형\n(조기집중형)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '51':
          zzanutPymCdNm = '체증연금형(5%)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '52':
          zzanutPymCdNm = '체증연금형(10%)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '53':
          zzanutPymCdNm = '순수종신연금\n(부부형70%)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '54':
          zzanutPymCdNm = '순수종신연금\n(부부형100%)'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증'
          break
        case '60':
          zzanutPymCdNm = '브릿지연금형'
          zzantPymPrdVl = zzantPymPrdVl + '년 보증_(' + parentPram.zzearlCnctYVl + '년,' + parentPram.zzearlCnctMVl + '배)'
          break
        default:
          zzanutPymCdNm = ''
          zzantPymPrdVl = ''
          break
      }
      colNo = 1
      rows[rowNum]['arg' + colNo++] = zzanutPymCdNm
      rows[rowNum]['arg' + colNo++] = zzantPymPrdVl

      this.lv_MaxValue = 0
      for (let obj in ZPowerIanutTotRcvExptAmtDto) {
        rows[rowNum]['arg' + colNo++] = ZPowerIanutTotRcvExptAmtDto[obj].powerIanutMppsn
        this.lv_MaxValue = Math.max(this.lv_MaxValue, ZPowerIanutTotRcvExptAmtDto[obj].powerIanutMppsn)
      }
      this.lv_Items = rows
      // tab title setting
      this.lv_HeaderItem = '0'
      // console.log(rows, '*****파워즉시 연금예시 - rows(2차 가공데이터)')
    },
  }
}
</script>