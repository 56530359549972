/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI168D
 * 화면설명: 결과보기(스마트 리포트) > 적립전환
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
 <template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt30 mb10">
        <mo-segment-wrapper solid primary v-model="lv_HeaderItem">
          <mo-segment-button v-for="(item, idx) in lv_HeaderTitle" :key="idx" :value="item.key">{{ item.label }}</mo-segment-button>
        </mo-segment-wrapper>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30">
        <!-- 이율 Segment -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area con-area">   
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_HeaderSubItem" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in lv_HeaderItems" :key="idx" :value="item.key">{{ item.lvyIrt }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- List -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20">
          <div class="full txt-right"><span class="crTy-bk7">(단위:원)</span></div>
          <div class="table-area mt10">
            <table class="table row-type">
              <colgroup>
                <col width="29%" />
                <col width="26%" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>경과기간</th>
                  <th>나이(세)</th>
                  <th>{{ lv_HeaderTitle[lv_HeaderIdx].label }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in lv_Items" :key="idx">
                  <td class="txt-center">{{ item.arg1 }}</td>
                  <td class="txt-center">{{ item.arg2 }}</td>
                  <td class="pr10 txt-right">{{ fn_NumberWithCommas(item['content' + lv_HeaderItem][lv_HeaderSubIdx]) }}</td>
                  <!-- <td class="pr10 txt-right" v-if="lv_HeaderItem === '1'">{{ item['content' + lv_HeaderItem][lv_HeaderSubIdx] }}</td> -->
                  <!-- <td class="pr10 txt-right" v-if="lv_HeaderItem === '2'">176,160,000</td> -->
                </tr>
              </tbody>
            </table>
          </div> 
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    parentParam: Object
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      lv_HeaderTitle: [{key: '1', label: '사망보험금'}, {key: '2', label: '해약환급금'}], // constant
      lv_HeaderItems: [],
      lv_Items: [],
      lv_HeaderItem: '',
      lv_HeaderIdx: 0,
      lv_HeaderSubItem: '',
      lv_HeaderSubIdx: 0
    }
  },

  created () {
    if (this.parentParam !== null) {
      console.log('BindData_MSPPI168DD')
      this.fn_MakeGrid(this.parentParam)
    }
  },
  mounted () {
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    lv_HeaderItem (item) {
      if (!_.isEmpty(item)) {
        this.lv_HeaderIdx = item - 1
        if (_.isEmpty(this.lv_HeaderSubItem)) {
          this.lv_HeaderSubItem = '1'
        }
      }
    },
    lv_HeaderSubItem (item) {
      if (!_.isEmpty(item)) {
        this.lv_HeaderSubIdx = item - 1
      }
    },
  },//watch
  computed: {},//computed
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    fn_NumberWithCommas (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    // 적립전환
    fn_MakeGrid (parentPramObj) {
      let parentPram = parentPramObj
      // let subjectTitle = '' // 제목타이틀
      // let irtTypCd = '' // 이율유형코드
      // let mainTitle = '' // 왼쪽 대타이틀
      let rightTitle = [] // 왼쪽 소타이틀[i]
      let ZacumCnvrSrdvl = [] // Main Data
      let colCount = 0 // 컬럼 갯수
      let rowCount = 0 // row 갯수
      let checkRow = false // rowCount 체크 유무
      let inCheckRow = false // rowCount 이중체크 유무
      let iCnt = 0 // acumCnvrSrdvl 위치
      let isByeon = false // 변액여부
      let isCnt = 0
      // console.log(parentPram.acumCnvrSrdvl, '적립전환 - parentPram.acumCnvrSrdvl(기초 데이터)')
      for (let i = 0; i < parentPram.acumCnvrSrdvl.length; i++) {
        if (parentPram.acumCnvrSrdvl[i].irtScCd === '04') {
          isByeon = true
          break
        }
      }
      for (let i = 0; i < parentPram.acumCnvrSrdvl.length; i++) {
        let rowData = {}
        let acumCnvrSrdvl = parentPram.acumCnvrSrdvl[i]

        rowData.lvyIrt = acumCnvrSrdvl.lvyIrt // 부리이율
        rowData.irtScCd = acumCnvrSrdvl.irtScCd // 이율구분코드

        if (acumCnvrSrdvl.ilstScCd === '0') {
          let acumCnvrSrdvlItmList = []

          if ((parentPram.acumCnvrSrdvl[i].acumCnvrSrdvlItm !== undefined) && parentPram.acumCnvrSrdvl[i].acumCnvrSrdvlItm.length > 0) {
            let acumItmRow = {}
            if (acumItmRow.tmpArg !== undefined) console.log('acumItmRow is not undefined')
            for (let j = 0; j < parentPram.acumCnvrSrdvl[i].acumCnvrSrdvlItm.length; j++) {
              let acumItmRow = {}
              let acumCnvrSrdvlItm = parentPram.acumCnvrSrdvl[i].acumCnvrSrdvlItm[j]

              if (parseInt(acumCnvrSrdvlItm.age, 10) >= 101) break

              acumItmRow.psgYr = acumCnvrSrdvlItm.psgYr// 전환이후 경과년도
              acumItmRow.age = acumCnvrSrdvlItm.age // 나이
              acumItmRow.genDthInsm = piCommonUtil.toChange(acumCnvrSrdvlItm.genDthInsm, 'number') // 사망보험금
              acumItmRow.mnInsrSrdvl = piCommonUtil.toChange(acumCnvrSrdvlItm.mnInsrSrdvl, 'number') // 해지환급금
              // acumItmRow.genDthInsm = this.toNumber(acumCnvrSrdvlItm.genDthInsm) // 사망보험금
              // acumItmRow.mnInsrSrdvl = this.toNumber(acumCnvrSrdvlItm.mnInsrSrdvl) // 해지환급금

              // pivot을 위한 row카운트
              if (!checkRow) {
                iCnt = colCount
                rowCount++
                inCheckRow = true
              }
              acumCnvrSrdvlItmList.push(acumItmRow)
            }
            // rowCount 이중체크
            if (inCheckRow) {
              checkRow = true
            }
            rowData.acumCnvrSrdvlItm = acumCnvrSrdvlItmList
          }

          // 우측타이틀 정보 설정
          let rightTitleValue = {}
          // let rightTitleValue = []

          let irtScCd = rowData.irtScCd
          // console.log('irtScCd  ' + irtScCd)
          if (isByeon) {
            if (irtScCd === '04') { // 투자수익형
              rightTitleValue.lvyIrt = '투자수익률 ' + piCommonUtil.toChange(rowData.lvyIrt, 'percent', 3) + '%'
              // rightTitleValue.lvyIrt = '투자수익률 ' + this.toPercent(rowData.lvyIrt, 3) + '%'
            } else {
              rightTitleValue.lvyIrt = '연복리' + (isCnt + 1)
            }
          } else {
            if (irtScCd === '03') {
              rightTitleValue.lvyIrt = '최저보증이율'
            } else {
              rightTitleValue.lvyIrt = '연복리 ' + piCommonUtil.toChange(rowData.lvyIrt, 'percent', 2) + '%'
              // rightTitleValue.lvyIrt = '연복리 ' + this.toPercent(rowData.lvyIrt, 2) + '%'
            }
          }
          rightTitleValue.key = `${i + 1}`
          rightTitle.push(rightTitleValue)
          // console.log('rightTitleValue ======> ', rightTitleValue)
          ZacumCnvrSrdvl.push(rowData)
          colCount++
        }
      }
      // console.log(ZacumCnvrSrdvl, '적립전환 - ZacumCnvrSrdvl(가공데이터)')

      // colCount = 연금지급 출력 리스트 갯수(세로)

      let rows = []
      // let gridData = []
      let rowNum = 0
      
      if ( ZacumCnvrSrdvl.length > 0 ) {
        for (let q = 0; q < rowCount; q++) {
          let row = {}
          row.content1 = []
          row.content2 = []
          for(let x=0; x < ZacumCnvrSrdvl.length; x++) {
            row.content1.push('')
            row.content2.push('')
          }
          rows.push(row)
        }
        // this.gridData8 = rows
        // let gridData = this.gridData8

        for (let i = 0; i < ZacumCnvrSrdvl.length; i++) {
          rowNum = 0
          for ( let j = 0; j < rowCount; j++) {
            if (iCnt === i) {
              rows[rowNum]['arg1'] = ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].psgYr + '년' // 경과기간
              rows[rowNum]['arg2'] = ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].age + '세' // 나이
              // rows[rowNum].content1 = [] // 사망보험금
              // rows[rowNum].content2 = [] // 해약환금급
            }
            rows[rowNum].content1[i] = (ZacumCnvrSrdvl[i].acumCnvrSrdvlItm === undefined) ? '-' : piCommonUtil.toChange(ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].genDthInsm, '') // 사망보험금
            rows[rowNum].content2[i] = (ZacumCnvrSrdvl[i].acumCnvrSrdvlItm === undefined) ? '-' : piCommonUtil.toChange(ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].mnInsrSrdvl, '') // 해지보험금
            // rows[rowNum].content1.push((ZacumCnvrSrdvl[i].acumCnvrSrdvlItm === undefined) ? '-' : piCommonUtil.toChange(ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].genDthInsm, '')) // 사망보험금
            // rows[rowNum].content2.push((ZacumCnvrSrdvl[i].acumCnvrSrdvlItm === undefined) ? '-' : piCommonUtil.toChange(ZacumCnvrSrdvl[i].acumCnvrSrdvlItm[j].mnInsrSrdvl, '')) // 해지보험금
            rowNum++
          }
        }// for
      }

      this.lv_Items = rows
      this.lv_HeaderItems = rightTitle
      this.lv_HeaderItem = '1'
      // console.log(rows, '적립전환 - rows(2차 가공데이터)')
      // console.log(rightTitle, '적립전환 - rightTitle')
      // console.log(gridData, '적립환금 - gridData')
    },
 },// methods

}//export default


</script>