/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI163D
 * 화면설명: 결과보기(스마트 리포트) > 환급금
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap">
      <!-- 상단 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area scroll-inner-top">
        <ur-box-container alignV="start" componentid="" direction="column" class="gray-box info-box pa2024 mb0">
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box">
            <span class="label-title min100 mb0">가정이율</span>
            <msp-bottom-select ref="bottomSelect" :disabled="lv_Options.length > 1 ? false : true" :items="lv_Options" :itemValue="'key'" :itemText="'label'" v-model="lv_Option" @selectedItem="fn_ClickEventCalled" underline class="ns-dropdown-sheet" placeholder="예정이율" bottom-title="가정이율" closeBtn/>
          </ur-box-container>
          <ur-box-container v-if="lv_GridCommnet.length > 0" alignV="start" componentid="" direction="column" class="mt10">
            <!-- 생애설계유니버설 상품 -->
            <ul class="terms-list-area line-h"> 
              <li v-for="(comm, commIdx) in lv_GridCommnet" :key="commIdx"><span class="fs14rem crTy-bk7">{{comm}}</span></li>
            </ul>
            <!-- 행복종신보험 -->
            <!-- <ul class="terms-list-area line-h">
              <li><span class="fs14rem crTy-bk7">특약을 가입하신 경우, 예시된 질병사망보험금은 주보험과 특약 보험금을 합산한 금액입니다.</span></li>
            </ul> -->

            <!-- 우리아이 올바른 성장보험[태아] -->
            <!-- <ul class="terms-list-area line-h">
              <li class="pb6"><span class="fs14rem crTy-bk7">예시된 경과기간은 출생예정일 이후의 경과기간입니다.</span></li>
              <li><span class="fs14rem crTy-bk7">졸업기념특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해약환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.</span></li>
            </ul> -->

            <!-- 우리아이 올바른 성장보험[출생아] -->
            <!-- <ul class="terms-list-area line-h">
              <li>
                <span class="fs14rem crTy-bk7">졸업기념특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해약환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.</span>
              </li>
            </ul> -->

            <!-- 연금보험 건강하게 여유만만 -->
            <!-- <ul class="terms-list-area line-h">
              <li>
                <span class="fs14rem crTy-bk7">예시된 납입보험료 누계는 실납입보험료(고액할인이 반영되어 실제로 납입하는 보험료)의 누계를 말하며, 환급률은 해약환급금을 실납입보험료의 누계로 나눈 비율입니다.</span>
              </li>
            </ul> -->
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <!-- 상단 End -->
      <ur-box-container class="area-bb flex-sc">
        <div class="flex-col-se f-end">
          <div class="wrap-btn">
            <mo-radio-wrapper v-model="radioStyle" class="ns-radio-list2">
              <mo-radio value="1" small>목록</mo-radio>
              <mo-radio value="2" small>표</mo-radio>
            </mo-radio-wrapper>
            <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="open2">크게보기</mo-button>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container v-if="radioStyle==='1'" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list list-pa2024 info-w100">
          <mo-list :list-data="lv_Items">
            <template #list-item="{item}">
              <mo-list-item  expandable prevent-expand>
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb10">
                    <span class="name txtSkip"><span class="fs19rem">{{item.psgPrd}}</span><em>|</em><span class="fs19rem">{{item.age}}세</span><em>|</em><span class="fwn fs17rem">환급률</span><span class="ml10 fs19rem">{{item.srdvlRto}}%</span></span>
                  </div>
                  <div class="list-item__contents__info row mb4">
                    <span class="full fs14rem crTy-bk7">납입보험료누계</span>
                    <span class="full fwb crTy-bk1 txt-right">{{item.rlpadPrmAggrAmt}}{{unitNm1}}</span>
                  </div>
                  <div class="list-item__contents__info row mb4">
                    <span class="full fs14rem crTy-bk7">해약환급금</span>
                    <span class="full fwb crTy-bk1 txt-right">{{item.srdvl}}{{unitNm1}}</span>
                  </div>
                  <div class="list-item__contents__info row">
                    <span class="full fs14rem crTy-bk7">사망보험금</span>
                    <span class="full fwb crTy-bk1 txt-right">{{item.genDthInsm}}{{unitNm1}}</span>
                  </div>
                </div>
                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                  <div class="list-item-detail">
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">인출</span><span class="crTy-bk1">{{item.midDrotAmt}}{{unitNm2}}</span>
                    </div>
                    <div class="contents-row fexTy3" v-if="entSrnEpId_12">
                      <span class="fs14rem crTy-bk7">재해사망보험금</span><span class="crTy-bk1">{{item.dstrDthInsm}}{{unitNm1}}</span>
                    </div>
                    <div class="contents-row fexTy3" v-if="entSrnEpId_12">
                      <span class="fs14rem crTy-bk7">질병사망보험금</span><span class="crTy-bk1">{{item.diseDthInsm}}{{unitNm1}}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">가산보험금</span><span class="crTy-bk1">{{item.addInsm}}{{unitNm1}}</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container> 

        <ur-box-container v-else class="sticky-pd">
          <div class="sticky-area">
            <div class="table-area sticky-t03" ref="tableArea" @scroll="checkScroll">
              <table class="table row-type ws_N">
                <thead>
                  <tr>
                    <th>경과시간</th>
                    <th>나이(세)</th>
                    <th>환급률(%)</th>
                    <th>납입보험료누계(원)</th>
                    <th>해약환급금(원)</th>
                    <th>사망보험금(원)</th>
                    <th>인출(만원)</th>
                    <th>가산보험금(원)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in lv_Items" :key="i">
                    <td> {{row.psgPrd}} </td>
                    <td> {{row.age}}세 </td>
                    <td class="aR"> {{row.srdvlRto}}% </td>
                    <td class="aR"> {{row.rlpadPrmAggrAmt}}{{unitNm1}} </td>
                    <td class="aR"> {{row.srdvl}}{{unitNm1}} </td>
                    <td class="aR"> {{row.genDthInsm}}{{unitNm1}} </td>
                    <td class="aR"> {{row.midDrotAmt}}{{unitNm2}} </td>
                    <td class="aR"> {{row.addInsm}}{{unitNm1}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="icon-right2" @click="scrollToRight" ref="iconRight" v-show="showButton"><i></i></button>
            <button class="icon-down2 pos-fixed" @click="scrollToDown" ref="iconDown" v-show="showButton"><i></i></button>
            <!-- <button class="icon-left" @click="scrollToLeft"><i></i></button>
            <button class="icon-up" @click="scrollToUp"><i></i></button> -->
            <!-- <div class="chevron_area">
              <div class="chevron">
                <a href="#">
                  <i></i>
                  <span>더보기</span>
                </a>
              </div>
            </div> -->
          </div>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>

    <!-- PT MODE -->
    <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
      <mo-top-bar>
        <mo-button class="btn_popup_back"></mo-button>
        <div slot="nav" class="icon-wrapper" @click="close2">
          <mo-icon color="white" borderless>drawer</mo-icon>
        </div>
        <div class="ui-gnb-title__main">환급금</div>
      </mo-top-bar>
      <!-- content 영역 -->
      <div class="content-area">
        <ur-box-container alignV="start" direction="column" class="ns-list-area">
          <ur-box-container>
            <div class="sticky-area">
              <div class="table-area sticky-t03" ref="tableAreaPop" @scroll="checkScrollPop">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>경과시간</th>
                      <th>나이(세)</th>
                      <th>환급률(%)</th>
                      <th>납입보험료누계(원)</th>
                      <th>해약환급금(원)</th>
                      <th>사망보험금(원)</th>
                      <th>인출(만원)</th>
                      <th>가산보험금(원)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in lv_Items" :key="i">
                      <td> {{row.psgPrd}} </td>
                      <td> {{row.age}}세 </td>
                      <td class="aR"> {{row.srdvlRto}}% </td>
                      <td class="aR"> {{row.rlpadPrmAggrAmt}}{{unitNm1}} </td>
                      <td class="aR"> {{row.srdvl}}{{unitNm1}} </td>
                      <td class="aR"> {{row.genDthInsm}}{{unitNm1}} </td>
                      <td class="aR"> {{row.midDrotAmt}}{{unitNm2}} </td>
                      <td class="aR"> {{row.addInsm}}{{unitNm1}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right" @click="scrollToRightPop" ref="iconRightPop" v-if="showPopButton"><i></i></button>
              <button class="icon-down" @click="scrollToDownPop" ref="iconDownPop" v-if="showPopButton"><i></i></button>
              <!-- <button class="icon-left" @click="scrollToLeft"><i></i></button>
              <button class="icon-up" @click="scrollToUp"><i></i></button> -->
              <!-- <div class="chevron_area">
                <div class="chevron">
                  <a href="#">
                    <i></i>
                    <span>더보기</span>
                  </a>
                </div>
              </div> -->
            </div>
          </ur-box-container>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>

  </ur-box-container>

</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import Gaf from '@/ui/pi/common/Gaf.js'
export default {
   /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI163D', 
  screenId: 'MSPPI163D',
  props: {
    parentParam: Object
  }, // parents data
  data() {
    return {
      radioStyle: '2',
      showButton: true,
      showPopButton: true,
      lv_GridCommnet: [],
      // lv_GridCommnet: '',
      lv_Options: '',
      lv_Option: '0',
      // lv_Option: {key: '', label: ''},
      lv_TotalItems: [], // {},
      lv_Items: [],
      currencyId: '', // 외화보험 통화코드
      unitNm1: '', // 외화보험 통화단위1 원/$
      unitNm2: '', // 외화보험 통화단위2 만원/$
      entSrnEpId_12: false // 인생맞춤종신보험
      /* lv_Items 구성요소 예시 {
        psgPrd: '3개월', // 경과기간
        age: '46', // 나이(세)
        rlpadPrmAggrAmt: '100,800', // 납입보험료누계
        midDrotAmt: 0, // 인출(만원)
        genDthInsm: 0, // 사망보험금(원)
        addInsm: 0, // 가산보험금(원)
        srdvl: 0, // 해지환급금(원)
        srdvlRto: 0 // 환급률
      } */
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    if (this.parentParam !== null) {
      console.log('MSPPI163D')
      this.fn_MakeGrid(this.parentParam)
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_ClickEventCalled
    * 설명       : fdp select 에서 선택하고 나서 핸들러
    ******************************************************************************/
    fn_ClickEventCalled () {
      this.lv_Items = this.lv_TotalItems[+this.lv_Option]
      // this.lv_Items = this.lv_TotalItems[this.lv_Option-1]
      // this.lv_Items = this.lv_TotalItems[+this.lv_Option]
      // this.lv_Items = this.lv_TotalItems[this.lv_Option.key]
      // this.$refs.fdpinfinite.moveScrollTop()
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentParamObj) {
      // ※참고
      // ASR190401302 (사랑ON)올바른성장보험 개발관련 결과보기 화면 문구추가(19.05.23)
      // ASR190800696 [Sub](가설)TDF변액연금 신상품 관련 가입설계 화면 개발
      let entSrnEpId = '' // 가설화면예외코드(ex: 'A1,A4')
      let prodInfo = Gaf.getTObj('prodInfo')
      if (prodInfo.baseInfoDto.zzentSrnEpId !== undefined && prodInfo.baseInfoDto.zzentSrnEpId !== 'undefined') {
        entSrnEpId = prodInfo.baseInfoDto.zzentSrnEpId
        if (entSrnEpId.indexOf('12') > -1) this.entSrnEpId_12 = true
      }

      this.lv_TotalItems.length = 0
      this.fn_MakeIrtSel(parentParamObj)
      let parentPram = parentParamObj
      // psgPrdAge
      // 22.01 young21.kwon ASR211201017 / [상품판매준비] (사랑ON)간편암든든/암든든플 결과보기화면 해지환급금 항목변경
      let blnSrvcChk = false
      // [2023-07-04] ASR230601126 / [상품판매준비] (간편)암든든플 결과보기화면 해지환급금 예시기준 변경대상 추가
      // (현행)LP0652, LP0646 -> (변경)LP0652, LP0646, LP0703, LP0704
      // ASR230900223_[상품판매준비] 고품격보장보험 결과보기 해지환급금 예시기준 예외_Next사랑On_youngki78.lee
      if (piCommonUtil.isObj(parentPram.productCode) && parentPram.productCode.length > 5 && 
            (parentPram.productCode.substring(0, 6) === 'LP0652' || 
            parentPram.productCode.substring(0, 6) === 'LP0646' || 
            parentPram.productCode.substring(0, 6) === 'LP0703' || 
            parentPram.productCode.substring(0, 6) === 'LP0704' || 
            parentPram.productCode.substring(0, 6) === 'LP0711' || 
            parentPram.productCode.substring(0, 6) === 'LP0712' ||
            parentPram.productCode.substring(0, 6) === 'LP0727' ||
            parentPram.productCode.substring(0, 6) === 'LP0729')) {
        blnSrvcChk = true
      }

      let PrpadIlstYn = 'N' // 선납 유무 체크

      // 외화보험 통화코드
      this.currencyId = parentParamObj.basicInfo[0].currencyId
      if (this.currencyId === 'KRW') {
        this.unitNm1 = '원'
        this.unitNm2 = '만원'
      } else if (this.currencyId === 'USD') {
        this.unitNm1 = '$'
        this.unitNm2 = '$'
      } else {
        this.unitNm1 = ''
        this.unitNm2 = ''
      }

      for (let i = 0; i < parentPram.srdvl.length; i++) {
        if (parentPram.srdvl[i].prpadIlstYn === 'Y') {
          PrpadIlstYn = 'Y'
          break
        }
      }
      let blnDrot = false
      if (PrpadIlstYn === 'Y' && (parseInt(parentPram.asgnDrotBgnAge, 10) !== 0 || parentPram.midDrotYn === 'Y')) {
        blnDrot = true
      }

      let reFndLvngFd = false // 생활자금 받는 상품 여부 체크
      if ((parentPram.htpsIlstPtclrTypCd !== undefined) && parentPram.htpsIlstPtclrTypCd.search('15') > -1) {
        reFndLvngFd = true
      }

      for (let i = 0; i < parentPram.srdvl.length; i++) {
        let gridData3 = []
        let el = parentPram.srdvl[i]
        if (PrpadIlstYn === 'Y') {
          if (el.prpadIlstYn === 'Y') continue

          if (blnDrot) {
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'N')) continue
          } else {
            if (!(el.diseSurgOccuYn === 'N')) continue
          }
        } else {
          if (reFndLvngFd) { // 생활자금받는 상품
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'Y')) continue
          } else {
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'N')) continue
          }
        }

        if (el.srdvlItm.length > 0) {
          for (let j = 0; j < el.srdvlItm.length; j++) {
            let row = {}
            if (j < parentPram.ccont.length) {
              // ASR200100689_[Sub][P0589] 실적배당연금 결과보기 화면개발 요청(사랑On)
              // TDF 변액연금 제1연금개시시점까지만 해약예시를 하기위해 (msg.pm 김성준 선임, 이채린 프로 요청) 2019-08-22
              // ASR190800696 [Sub](가설)TDF변액연금 신상품 관련 가입설계 화면 개발
              // if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('10') > -1 && !piCommonUtil.isEmpty2(parentPram.anutBgnAge)) { // TDF 변액연금보험
              //   if (parseInt(parentPram.ccont[j].age, 10) > (parseInt(parentPram.anutBgnAge, 10) - 10)) {
              //     break
              //   }
              // }
              if (el.prpadIlstYn === 'Y' && j < 3) continue

              if (j < 3) {
                row.psgPrd = parentPram.ccont[j].psgMntFgr + '개월'
              } else {
                row.psgPrd = parentPram.ccont[j].psgYr + '년'
              }
              row.age = parentPram.ccont[j].age
              if (parentPram.lgatContAppnCd === 'Y') {
                row.rlpadPrmAggrAmt = piCommonUtil.toChange(parentPram.ccont[j].rlpadPrmAggrAmt, 'number')
                // row.rlpadPrmAggrAmt = this.toNumber(parentPram.ccont[j].rlpadPrmAggrAmt)
                row.srdvlRto = el.srdvlItm[j].rlpadPrmSrdvlRto
              } else {
                row.rlpadPrmAggrAmt = piCommonUtil.toChange(parentPram.ccont[j].padPrmAggrAmt, 'number')
                // row.rlpadPrmAggrAmt = this.toNumber(parentPram.ccont[j].padPrmAggrAmt)
                if (reFndLvngFd) { // 생활자금받는 상품
                  row.srdvlRto = el.srdvlItm[j].totPymAmtRto
                } else {
                  // 22.01 young21.kwon ASR211201017 / [상품판매준비] (사랑ON)간편암든든/암든든플 결과보기화면 해지환급금 항목변경
                  // row.srdvlRto = el.srdvlItm[j].srdvlRto
                  if (blnSrvcChk) {
                    row.srdvlRto = el.srdvlItm[j].srdvlTotCncrSrvcRto // 서비스포함환급률
                  } else {
                    row.srdvlRto = el.srdvlItm[j].srdvlRto
                  }
                }
              }
              // 202005 외화보험 만단위 체크
              row.midDrotAmt = this.currencyId === 'KRW' ? piCommonUtil.toChange(parentPram.ccont[j].midDrotAmt, 'unit') : parentPram.ccont[j].midDrotAmt
              // row.midDrotAmt = this.currencyId === 'KRW' ? this.toManWon(parentPram.ccont[j].midDrotAmt) : parentPram.ccont[j].midDrotAmt
            } else {
              row.psgPrd = '-'
              row.age = '-'
              row.rlpadPrmAggrAmt = '-'
              row.srdvlRto = '-'
              row.midDrotAmt = '-'
            }

            // 원금액처리
            row.dstrDthInsm = piCommonUtil.toChange(el.srdvlItm[j].dstrDthInsm, 'number') // 재해사망보험금
            row.diseDthInsm = piCommonUtil.toChange(el.srdvlItm[j].diseDthInsm, 'number') // 질병사망보험금
            row.genDthInsm = piCommonUtil.toChange(el.srdvlItm[j].genDthInsm, 'number') // 일반사망보험금
            row.addInsm = piCommonUtil.toChange(el.srdvlItm[j].addInsm, 'number') // 가산보험금
            // row.dstrDthInsm = this.toNumber(el.srdvlItm[j].dstrDthInsm) // 재해사망보험금
            // row.diseDthInsm = this.toNumber(el.srdvlItm[j].diseDthInsm) // 질병사망보험금
            // row.genDthInsm = this.toNumber(el.srdvlItm[j].genDthInsm) // 일반사망보험금
            // row.addInsm = this.toNumber(el.srdvlItm[j].addInsm) // 가산보험금
            if (reFndLvngFd) { // 생활자금받는 상품
              row.srdvl = piCommonUtil.toChange(el.srdvlItm[j].totPymAmt, 'number') // 해지환급금
              // row.srdvl = this.toNumber(el.srdvlItm[j].totPymAmt) // 해지환급금
            } else {
              // 22.01 young21.kwon ASR211201017 / [상품판매준비] (사랑ON)간편암든든/암든든플 결과보기화면 해지환급금 항목변경
              if (blnSrvcChk) {
                row.srdvl = piCommonUtil.toChange(el.srdvlItm[j].srdvlTotCncrSrvc, 'number') // 서비스포함해약환급금
                // row.srdvl = this.toNumber(el.srdvlItm[j].srdvlTotCncrSrvc) // 서비스포함해약환급금
              } else {
                row.srdvl = piCommonUtil.toChange(el.srdvlItm[j].srdvl, 'number') // 외화보험
                // row.srdvl = this.toNumber(el.srdvlItm[j].srdvl) // 외화보험
              }
            }

            // 202005 외화보험 원화 표시 처리
            row.rlpadPrmAggrAmt = this.$bizUtil.numberWithCommasCurr(row.rlpadPrmAggrAmt, this.currencyId, 'Y', 'N') // 납입보험료누계(원)
            row.dstrDthInsm = this.$bizUtil.numberWithCommasCurr(row.dstrDthInsm, this.currencyId, 'N', 'N') // 사망보험금(만원)
            row.diseDthInsm = this.$bizUtil.numberWithCommasCurr(row.diseDthInsm, this.currencyId, 'N', 'N') // 사망보험금(만원)
            row.genDthInsm = this.$bizUtil.numberWithCommasCurr(row.genDthInsm, this.currencyId, 'N', 'N') // 사망보험금(만원)
            row.addInsm = this.$bizUtil.numberWithCommasCurr(row.addInsm, this.currencyId, 'N', 'N') // 가산보험금(원)
            row.srdvl = this.$bizUtil.numberWithCommasCurr(row.srdvl, this.currencyId, 'Y', 'N') // 해지환급금(원)
            row.midDrotAmt = this.$bizUtil.numberWithCommasCurr(row.midDrotAmt, this.currencyId, 'N', 'N') // 인출(만원)
            // to-be
            if (this.entSrnEpId_12) {
              row.psgPrdAge = `${row.psgPrd} (${row.age}세)`
            }
            gridData3.push(row)
          }
        }
        // break
        // }
        this.lv_TotalItems[i] = gridData3
      } // end For
      // this.lv_Option = this.lv_Options[0].key
      this.lv_Option = this.lv_Options[0].key + ''
      // this.lv_Option = this.lv_Options[0]
      this.lv_Items = this.lv_TotalItems[+this.lv_Option]
      // this.lv_Items = this.lv_TotalItems[this.lv_Option.key]
      // console.log('[MSPPI163D] lv_Items ======> ', this.lv_Items)

      // comment
      let grid3comment = []
      // let grid3comment = ''
      if (reFndLvngFd) {
        grid3comment = ['예시된 해약환급금은 생애설계자금 수령시 해약환급금과 생애설계자금누계액을 합한 금액입니다.']
      } else {
        // TEST위하여추가 (201904박희윤) : 태아보험 예외처리 필요함
        let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
        if (zzptclrPrdTId === '29') {
          grid3comment = ['예시된 경과기간은 출생예정일 이후의 경과기간입니다.']
          // grid3comment = '※ 상기 예시된 경과기간은 출생예정일 이후의 경과기간입니다.'
          if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('08') > -1) { // 올바른성장보험
            grid3comment.push('졸업기념금특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해약환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.')
            // grid3comment += '<br/>졸업기념금특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해약환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.'
            // grid3comment += '<br/>※ 졸업기념금특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해지환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.'
          }
        } else {
          if (parentPram.lgatContAppnCd === 'Y') {
            if (parentPram.htpsReprClsfCd === '1') {
            grid3comment = ['예시된 납입보험료 누계는 고액할인은 반영하나 동시가입할인은 미반영한 보험료의 누계를 말하며, 환급률은 해약환급금을 실납입보험료의 누계로 나눈 비율입니다.']
              // grid3comment = '※ 상기 예시된 납입보험료 누계는 고액할인은 반영하나 동시가입할인은 미반영한 보험료의 누계를 말하며, 환급률은 해지환급금을 실납입보험료의 누계로 나눈 비율입니다.'
            } else if (parentPram.htpsReprClsfCd === '3') {
              grid3comment = ['예시된 납입보험료 누계는 실납입보험료(고액할인이 반영되어 실제로 납입하는 보험료)의 누계를 말하며, 환급률은 해약환급금을 실납입보험료의 누계로 나눈 비율입니다.']
              // grid3comment = '※ 상기 예시된 납입보험료 누계는 실납입보험료(고액할인이 반영되어 실제로 납입하는 보험료)의 누계를 말하며, 환급률은 해지환급금을 실납입보험료의 누계로 나눈 비율입니다.'
            }
          } else if (parentPram.lgatContAppnCd === 'N') {
            if (parentPram.htpsReprClsfCd === '1') {
              grid3comment = ['예시된 납입보험료누계는 동시가입할인은 미반영한 보험료의 누계를 말합니다.']
              // grid3comment = '※ 상기 예시된 납입보험료누계는 동시가입할인은 미반영한 보험료의 누계를 말합니다.'
            } else if (parentPram.htpsReprClsfCd === '3') {
              grid3comment = ['예시된 해약환급금은 고액적립금액이 반영되어 계산된 금액입니다.']
              // grid3comment = '※ 상기 예시된 해지환급금은 고액적립금액이 반영되어 계산된 금액입니다.'
            }
          } else {
            grid3comment = '' // 없음
          }
          if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('08') > -1) { // 올바른성장보험
            grid3comment = ['졸업기념금특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해약환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.']
            // grid3comment = '※ 졸업기념금특약K(무배당), 대학학자금특약K(무배당)을 가입하신 경우 해지환급금에서 해당 특약의 환급금은 25세 계약해당일 전일까지 포함되어 있습니다.'
          }
          if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('12') > -1) { // 행복종신보험
            grid3comment = ['특약을 가입하신 경우, 예시된 질병사망보험금은 주보험과 특약 보험금을 합산한 금액입니다.']
            // grid3comment = '※ 특약을 가입하신 경우, 상단에 예시된 질병사망보험금은 주보험과 특약 보험금을 합산한 금액입니다.'
          }
        }
      }
      this.lv_GridCommnet = grid3comment
    },
    /******************************************************************************
    * Function명 : fn_MakeIrtSel
    * 설명       : option list 구성
    ******************************************************************************/
    fn_MakeIrtSel (parentPramObj) {
      let PrpadIlstYn = 'N' // 선납 유무 체크
      let parentPram = parentPramObj
      for (let i = 0; i < parentPram.srdvl.length; i++) {
        if (parentPram.srdvl[i].prpadIlstYn === 'Y') {
          PrpadIlstYn = 'Y'
          break
        }
      }
      let blnDrot = false
      if (PrpadIlstYn === 'Y' && (parseInt(parentPram.asgnDrotBgnAge, 10) !== 0 || parentPram.midDrotYn === 'Y')) {
        blnDrot = true
      }

      let reFndLvngFd = false // 생활자금 받는 상품 여부 체크
      if ((parentPram.htpsIlstPtclrTypCd !== undefined) && parentPram.htpsIlstPtclrTypCd.search('15') > -1) {
        reFndLvngFd = true
      }

      let irtSel = []// 이율 {text : '자녀3', key : '5', desc : '자녀3'}
      for (let i = 0; i < parentPram.srdvl.length; i++) {
        let el = parentPram.srdvl[i]
        let option = {}

        if (PrpadIlstYn === 'Y') {
          if (el.prpadIlstYn === 'Y') continue

          if (blnDrot) {
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'N')) continue
          } else {
            if (!(el.diseSurgOccuYn === 'N')) continue
          }
        } else {
          if (reFndLvngFd) { // 생활자금받는 상품
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'Y')) continue
          } else {
            if (!(el.diseSurgOccuYn === 'N' && el.lvngFdRcvYn === 'N')) continue
          }
        }
        // option.key = i + 1
        option.key = i + ''
        // console.log('option.key ==> ', option.key)
        if (el.lvyIrtCd === '10') continue
        if (el.lvyIrtCd === '01') {
          if (PrpadIlstYn === 'Y') {
            if (el.lvngFdRcvYn === 'Y') {
              option.text = '예정이율 (생활자금有)'
            } else {
              option.text = '예정이율 (생활자금無)'
            }
          } else {
            option.text = '예정이율'
          }
        } else if (el.lvyIrtCd === '02' || el.lvyIrtCd === '07' || el.lvyIrtCd === '08') {
          option.text = `연복리 ${piCommonUtil.toChange(el.lvyIrt, 'percent', 2)}% 가정시`
          // console.log('=======> ', piCommonUtil.toChange(el.lvyIrt, 'percent', 2))
          if (PrpadIlstYn === 'Y') {
            if (el.lvngFdRcvYn === 'Y') {
              option.text += ' (생활자금有)'
              // option.text = '연복리 ' + this.toPercent(el.lvyIrt, 2) + '% 가정시 (생활자금有)'
            } else {
              option.text += ' (생활자금無)'
              // option.text = '연복리 ' + this.toPercent(el.lvyIrt, 2) + '% 가정시 (생활자금無)'
            }
          } else {
            // option.text = '연복리 ' + this.toPercent(el.lvyIrt, 2) + '% 가정시'
          }
        } else if (el.lvyIrtCd === '03') {
          if (PrpadIlstYn === 'Y') {
            if (el.lvngFdRcvYn === 'Y') {
              option.text = '최저보증이율 가정시 (생활자금有)'
            } else {
              option.text = '최저보증이율 가정시 (생활자금無)'
            }
          } else {
            option.text = '최저보증이율 가정시'
          }
        } else if (el.lvyIrtCd === '04') {
          option.text = `투자수익률 ${piCommonUtil.toChange(el.lvyIrt, 'percent',3)}% 가정시`
          if (PrpadIlstYn === 'Y') {
            if (el.lvngFdRcvYn === 'Y') {
              option.text += ' (생활자금有)'
              // option.text = '투자수익률 ' + this.toPercent(el.lvyIrt, 3) + '% 가정시 (생활자금有)'
            } else {
              option.text += ' (생활자금無)'
              // option.text = '투자수익률 ' + this.toPercent(el.lvyIrt, 3) + '% 가정시 (생활자금無)'
            }
          } else {
            // option.text = '투자수익률 ' + this.toPercent(el.lvyIrt, 3) + '% 가정시'
          }
        }
        option.label = option.text
        irtSel.push(option)
      }
      this.lv_Options = irtSel
    },

    open2() { this.$refs.nsbottomsheet2.open();  },
    close2() { this.$refs.nsbottomsheet2.close(); },

    checkScroll() {
      // const nums = this.$refs.tableArea.scrollLeft + this.$refs.tableArea.scrollTop;
      const nums = this.$refs.tableArea.scrollLeft
      if(nums > 0) { this.showButton = false; }
      // else if(nums == 0) { this.showButton = true; }
    },
    scrollToRight() {
      const tableArea = this.$refs.tableArea;
      const scrollAmount = 200;
      const scrollDuration = 1000;
      const startingPosition = tableArea.scrollLeft;
      const targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
      },
    scrollToDown() { 
      const tableArea = this.$refs.tableArea;
      const scrollAmount = 200;
      const scrollDuration = 1000;
      const startingPosition = tableArea.scrollTop;
      const targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      },

    checkScrollPop() {
      const leftNums = this.$refs.tableAreaPop.scrollLeft
      const rightNums = this.$refs.tableAreaPop.scrollTop;
      if(leftNums > 0 || rightNums) { this.showPopButton = false; } 
      // else if(nums == 0) { this.showPopButton = true; }
    },
    scrollToRightPop() {
      const tableArea = this.$refs.tableAreaPop;
      const scrollAmount = 200;
      const scrollDuration = 1000;
      const startingPosition = tableArea.scrollLeft;
      const targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
      },
    scrollToDownPop() { 
      const tableArea = this.$refs.tableAreaPop;
      const scrollAmount = 200;
      const scrollDuration = 1000;
      const startingPosition = tableArea.scrollTop;
      const targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      },
    
    scrollToLeft() { 
      this.$refs.tableArea.scrollLeft -= 200;
      },
    scrollToUp() { 
      this.$refs.tableArea.scrollTop -= 200;
    },
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);

        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }

        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    },
    fn_showButtonInit(){
      this.showButton = true
      this.showPopButton = true
    }

  }
}
</script>
