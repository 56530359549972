/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI162D
 * 화면설명: 결과보기(스마트 리포트) > 보장내용
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap"
                      v-if="lv_ListData1.length > 0" v-for="(item, idx) in lv_ListData1" :key="idx">
      <!-- <template v-if="lv_ListData1.length > 0" v-for="(item, idx) in lv_ListData1"> -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area pa2024">  
          <div class="full fexTy3 align-item-start">
            <b class="fs19rem fexInt w80 pal0">{{item.prtInsrNm}}</b>
            <mo-badge :class="fn_GetClass(item.InsrNm)" text="" shape="status">{{ item.InsrNm }}</mo-badge>
            <!-- class="lightblue" : 주피, class="lightblue4" : 종피 -->
          </div>
        </ur-box-container>   
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" :class="idx === lv_ListData1.length-1 ? 'ns-add-area pt0 pb120':'ns-add-area pt0'">
        <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt0 pb120"> -->
          <div class="table-area">
            <table class="table row-type pa-type">
            <colgroup>
                <col width="68%" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>지급사유</th>
                  <th>지급금액</th>
                </tr>
              </thead>
              <tbody v-for="(item2, idx2) in item.detail" :key="idx2">
                <!-- <template v-for="(item2, idx2) in item.detail"> -->
                  <tr v-if="item2.covrCmntYn !== 'Y'">
                    <td v-html="item2.covrCntnt"></td>
                    <!-- <td>{{item2.covrCntnt}}</td> -->
                    <td v-html="item2.covrAmt"></td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="terms-list-area fs14rem">
                        <li class="pb6">
                          <span class="crTy-bk7" v-html="item2.covrCntnt"></span>
                          <!-- <span class="crTy-bk7">{{item2.covrCntnt}}</span> -->
                        </li>
                      </ul>
                    </td>
                    <td></td>
                  </tr>
                <!-- </template> -->
              </tbody>
            </table>
          </div>        
        </ur-box-container>
      <!-- </template> -->
    </ur-box-container>
    <!-- NoData 영역: start  -->
    <ur-box-container v-if="lv_ListData1.length === 0" alignV="start" componentid="ur_box_container_002" direction="column" class="no-data">
    <!-- <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="no-data"> -->
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- NoData 영역: end  -->
    <!-- 스마트폰에서만 노출, 태블릿에서는 비노출
         기능삭제
     -->
    <!-- <ur-box-container v-show="lv_mobileYn" alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenPopup">보장내용 가로보기</mo-button>
      </div>
    </ur-box-container> -->
  </ur-box-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import GafMainFunc from '@/ui/pi/common/GafMainFunc'
// import MSPPI169P from '@/ui/pi/MSPPI169P'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI162D', 
  screenId: 'MSPPI162D',
  props: {
    parentParam: Object,
    isMobileEnv: String
  }, // parents data
  data() {
    return {
      lv_mobileYn: false,
      lv_ListData1: []
      /* // lv_ListData1 구성
      [{
        prtInsrNm: 'VIP정기보험5.0(무배당)', // 보험명
        InsrNm: '주피', // 관계자
        detail: [{
          detailExplain: '', // 상세설명
          covrCntnt: '사망시', // 지급사유
          covrAmtFlag: '2',
          covrAmt: '3,000만원' // 지급금액
        }]
      }] */
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    if (this.parentParam !== null) {
      console.log('MSPPI162D :', this.isMobileEnv)
      // 운영계 전까지 로컬에서 확인을 위해 모바일환경 구분 값 세팅
      // if (process.env.NODE_ENV === 'local') {
      //   if (this.isMobileEnv === 'O' || this.isMobileEnv === 'P') {
      //     this.lv_mobileYn = true
      //   } else {
      //     this.lv_mobileYn = false
      //   }
      // } else {
      //   if (this.isMobileEnv === 'P') {
      //     this.lv_mobileYn = true
      //   } else {
      //     this.lv_mobileYn = false
      //   }
      // }
      this.fn_MakeGrid(this.parentParam)
    }
  },
  mounted () {
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_GetClass (item) {
      switch (item) {
        case '주피':
          return 'badge-sample-badge lightblue'
        // case '계약자':
        //   return '-pub-product-join__tag--contractor'
        case '종피':
          return 'badge-sample-badge lightblue4'
        default:
          return 'badge-sample-badge lightblue'
      }
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentPramObj) {
      // 결과보기(임시) interFace 에 대해 생각해볼 필요가 있씀
      let parentPram = parentPramObj
      let gridData2 = []
      let zEntplCovrAftClPrmOutElemDto = GafMainFunc.copyJson(parentPram.covrBft)
      // console.log(zEntplCovrAftClPrmOutElemDto, ' <== [MSPPI162D] fn_MakeGrid')

      // 관계자 정보 설정 및 보험명, 지급사유 설정
      for (let i = 0; i < zEntplCovrAftClPrmOutElemDto.length; i++) {
        let el = zEntplCovrAftClPrmOutElemDto[i]
        let prtInsrNm = ''
        if (el.zzcontvInsrdCd === '23') {
          prtInsrNm = '종피'
        } else if (el.zzcontvInsrdCd === '24') {
          // to-be NEXT 사랑On에서는 자녀 X
          // if (el.zzchldSnoVl === 2) {
          //   prtInsrNm = '자녀2'
          // } else if (el.zzchldSnoVl === 3) {
          //   prtInsrNm = '자녀3'
          // } else {
          //   prtInsrNm = '자녀1'
          // }
        } else {
          prtInsrNm = '주피'
        }
        el.InsrNm = prtInsrNm
        // console.log(`${i} ===> 치환전 보장내용 : ${el.covrCntnt}`)
        el.covrCntnt = el.covrCntnt.replace(/\n/gi, '')
        // el.covrCntnt = el.covrCntnt.replace(/\n/gi, '').replace(/\<br\>/gi, '\n')

        // ASR200800782 [시스템개선]가입설계 결과보기 보장내용 주석처리 변경_사랑on
        // covrCmntYn 이 Y 면 앞에 것과 합쳐줘야한다.
        // if (el.covrCmntYn === 'Y') {
        //   cnt++
        //   zEntplCovrAftClPrmOutElemDto[i - cnt].covrCntnt = zEntplCovrAftClPrmOutElemDto[i - cnt].covrCntnt.concat('<br>'.concat(el.covrCntnt))
        // } else {
        //   cnt = 0
        // }
      }
      // console.log(this.$bizUtil.cloneDeep(zEntplCovrAftClPrmOutElemDto), '[MSPPI162D] Grid 1 ======')
        // seq 가 1 이면 gridData 에 추가할 항목이다.
        // for (let i = 0; i < zEntplCovrAftClPrmOutElemDto.length; i++) {
        //   if (zEntplCovrAftClPrmOutElemDto[i].seq === '1') {
        //     zEntplCovrAftClPrmOutElemDto[i].detail = []
        //     zEntplCovrAftClPrmOutElemDto[i].detail.push({
        //       detailExplain: '',
        //       covrCntnt: zEntplCovrAftClPrmOutElemDto[i].covrCntnt,
        //       covrAmtFlag: '1', // zEntplCovrAftClPrmOutzEntplCovrAftClPrmOutElemDto[i]emDto[i].covrCntnt,
        //       covrAmt: zEntplCovrAftClPrmOutElemDto[i].covrAmt
        //     })
        //     gridData2.push(GafMainFunc.copyJson(zEntplCovrAftClPrmOutElemDto[i]))
        //   }
        // }
        // test
        // let gridData3 = []
      zEntplCovrAftClPrmOutElemDto.map(item => {
        if (item.seq === '1') {
          item.detail = [{
            detailExplain: '',
            covrCntnt: item.covrCntnt,
            covrAmtFlag: '1',
            covrAmt: item.covrAmt
          }]
          gridData2.push(GafMainFunc.copyJson(item))
          // gridData3.push(GafMainFunc.copyJson(item))
        }
      })
      // console.log(this.$bizUtil.cloneDeep(zEntplCovrAftClPrmOutElemDto), '[MSPPI162D] Grid 2 ======')
      // console.log(this.$bizUtil.cloneDeep(gridData2), '[MSPPI162D] gridData2 Grid 2 ======')
      // console.log(this.$bizUtil.cloneDeep(gridData3), '[MSPPI162D] gridData3 Grid 3 ======')
      // test
      gridData2.forEach(el => {
      // gridData3.forEach(el => {
        zEntplCovrAftClPrmOutElemDto.map(item => {
          if (item.seq !== '1' &&
          el.zzcontvInsrdCd === item.zzcontvInsrdCd &&
          el.zzchldSnoVl === item.zzchldSnoVl &&
          el.reprClcd === item.reprClcd &&
          el.prtInsrNm === item.prtInsrNm) {
            el.detail.push({
              covrCntnt: item.covrCntnt,
              covrAmtFlag: '1',
              covrAmt: item.covrAmt,
              covrCmntYn: item.covrCmntYn // ASR200800782 [시스템개선]가입설계 결과보기 보장내용 주석처리 변경_사랑on
            })
          }
        })
      })
      // end test
      // seq 가 1 이 아니면 gridData 에 있는 항목에 추가할 내용이다.
      // for (let i = 0; i < zEntplCovrAftClPrmOutElemDto.length; i++) {
      //   let el = zEntplCovrAftClPrmOutElemDto[i]
      //   if (el.seq !== '1') {
      //     for (let j = 0; j < gridData2.length; ++j) {
      //       if (gridData2[j].zzcontvInsrdCd === el.zzcontvInsrdCd &&
      //           gridData2[j].zzchldSnoVl === el.zzchldSnoVl &&
      //           gridData2[j].reprClcd === el.reprClcd &&
      //           gridData2[j].prtInsrNm === el.prtInsrNm) {
      //         gridData2[j].detail.push({
      //           covrCntnt: zEntplCovrAftClPrmOutElemDto[i].covrCntnt,
      //           covrAmtFlag: '1', // zEntplCovrAftClPrmOutzEntplCovrAftClPrmOutElemDto[i]emDto[i].covrCntnt,
      //           covrAmt: zEntplCovrAftClPrmOutElemDto[i].covrAmt,
      //           covrCmntYn: zEntplCovrAftClPrmOutElemDto[i].covrCmntYn // ASR200800782 [시스템개선]가입설계 결과보기 보장내용 주석처리 변경_사랑on
      //         })
      //       }
      //     }
      //   }
      // }
      // console.log(this.$bizUtil.cloneDeep(zEntplCovrAftClPrmOutElemDto), '[MSPPI162D] Grid 3 ======')
      // console.log(this.$bizUtil.cloneDeep(gridData2), '[MSPPI162D] gridData2 Grid 3 ======')
      // console.log(this.$bizUtil.cloneDeep(gridData3), '[MSPPI162D] gridData3 Grid 3 ======')
      // 지급금액에서 같은 지급사유는 합치기
      for (let i = 0; i < gridData2.length; i++) {
        // test
        let filterArr = gridData2[i].detail.filter((item, idx) => item.covrCntnt === '' && item.covrAmt !== '' && idx !== 0)
        // console.log(filterArr, '<== filterArr')
        // end test
        for (let j = 0; j < gridData2[i].detail.length; ++j) {
          if (gridData2[i].detail[j].covrCntnt === '' && gridData2[i].detail[j].covrAmt !== '' && j !== 0) {
            let mainReasonIdx = j - 1
            for (let k = j; k < gridData2[i].detail.length; ++k) {
              if (gridData2[i].detail[k].covrCntnt === '') {
                // console.log('================================== detail remove')
                gridData2[i].detail[mainReasonIdx].covrAmt += '<br>' + gridData2[i].detail[k].covrAmt
                gridData2[i].detail[k].covrAmt = '' // 위쪽에 붙인 건 삭제
              } else {
                break
              }
            }
          }
        }
      }
      // console.log(this.$bizUtil.cloneDeep(gridData2), '[MSPPI162D] gridData2 Grid 4 ======')
      this.lv_ListData1 = gridData2
      // console.log('lv_ListData1 =====> ', this.lv_ListData1)
    },
    /******************************************************************************
    * Function명 : fn_OpenPopup
    * 설명       : 보장내용 가로보기 팝업 오픈
    ******************************************************************************/
    fn_OpenPopup () {
      let lv_vm = this
      // 가로모드
    //   window.fdpbridge.exec('setOrientationLandscapePlugin', '', function (result) {
    //     // 성공로직
    //   }, function (result) {
    //     // 실패로직
    //   })
    //   let openMSPPI169P = this.$bottomModal.open(MSPPI169P, {
    //     properties: {
    //       pPage: 'MSPPI162D',
    //       parentParam: lv_vm.lv_ListData1
    //     },
    //     listeners: {
    //       closePopup: () => {
    //         // console.log('보장내용 팝업 종료')
    //         lv_vm.$bottomModal.close(openMSPPI169P)
    //         // 세로모드로 돌리기
    //         // window.fdpbridge.exec('setOrientationPortraitPlugin', '', function (result) {
    //         //   // 성공로직
    //         //   console.log('세로모드 성공')
    //         // }, function (result) {
    //         //   // 실패로직
    //         // })
    //       }
    //     }
    //   })
    }
  },
}
</script>