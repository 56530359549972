/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI166D
 * 화면설명: 결과보기(스마트 리포트) > 갱신보험료
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area con-area">
        <!-- 갱신주기 유형 : 1건(비노출) -->
        <ur-box-container v-if="lv_Items.length > 1" alignV="start" componentid="" direction="column" class="ns-segment pt30">
          <mo-segment-wrapper solid primary v-model="lv_SegmentItem" :class="lv_SetClass">
            <mo-segment-button v-for="(item, idx) in lv_SegmentItems" :key="idx" :value="item.key">{{ item.label }}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>
      <!-- 갱신주기 유형 END -->
      <!-- Contents START -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area" v-for="(item1, idx) in lv_SelItems" :key="idx">
        <!-- 상단고정 START -->
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt30">
          <div class="full pb10 bd-b-Ty1">
            <b class="fs17rem fexInt pal0">갱신주기 : <span class="crTy-blue3">{{ item1.renwCyl }}</span>년</b>
          </div>
        </ur-box-container>
        <!-- 상단고정 END -->
        <!-- List START -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list list-pa2024 info-w100">
          <mo-list :list-data="item1.items">
            <template #list-item="{item, index}">
              <mo-list-item expandable prevent-expand :ref="'ref_'+index">
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb10">
                    <span class="name txtSkip2 fs19rem">{{item.insrNm}}</span>
                  </div>
                  <div class="list-item__contents__info row mb4">
                    <span class="full fs14rem crTy-bk7">최초계약</span>
                    <span class="full fwb crTy-bk1 txt-right">{{!item.hasOwnProperty('zzzeroPcRPAm0') ? '' : +item.zzzeroPcRPAm0 === 0 ? '-' : item.zzzeroPcRPAm0 + '원'}}</span>
                  </div>
                  <div class="list-item__contents__info row mb4">
                    <span class="full fs14rem crTy-bk7">1회 갱신</span>
                    <span class="full fwb crTy-bk1 txt-right">{{!item.hasOwnProperty('zzzeroPcRPAm1') ? '' : +item.zzzeroPcRPAm1 === 0 ? '-' : item.zzzeroPcRPAm1 + '원'}}</span>
                  </div>
                  <div class="list-item__contents__info row">
                    <span class="full fs14rem crTy-bk7">2회 갱신</span>
                    <span class="full fwb crTy-bk1 txt-right">{{!item.hasOwnProperty('zzzeroPcRPAm2') ? '' : +item.zzzeroPcRPAm2 === 0 ? '-' : item.zzzeroPcRPAm2 + '원'}}</span>
                  </div>
                </div>

                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                  <div class="list-item-detail">
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">3회 갱신</span><span class="crTy-bk1">{{!item.hasOwnProperty('zzzeroPcRPAm3') ? '' : +item.zzzeroPcRPAm3 === 0 ? '-' : item.zzzeroPcRPAm3 + '원'}}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">4회 갱신</span><span class="crTy-bk1">{{!item.hasOwnProperty('zzzeroPcRPAm4') ? '' : +item.zzzeroPcRPAm4 === 0 ? '-' : item.zzzeroPcRPAm4 + '원'}}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">5회 갱신</span><span class="crTy-bk1">{{!item.hasOwnProperty('zzzeroPcRPAm5') ? '' : +item.zzzeroPcRPAm5 === 0 ? '-' : item.zzzeroPcRPAm5 + '원'}}</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <!-- List END -->
      </ur-box-container>
      <!-- Contents END -->
    </ur-box-container>
  </ur-box-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Gaf from '@/ui/pi/common/Gaf'
export default {
   /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI166D', 
  screenId: 'MSPPI166D',
  props: {
    parentParam: Object
  }, // parents data
  data() {
    return {
      // lv_ShowData: true,
      lv_SetClass: '',
      lv_SegmentItems: [],
      lv_SegmentItem: '',
      lv_SelItems: [], // 선택한 갱신주기 Array
      lv_Items: []
      /* lv_Items 구성 요소 {
        renwCyl: '3년',
        insrNm: '[주피]소액질병치료비특약N(갱신형,무배당)[최대100세갱신형]',
        zzzeroPcRPAm0: '4,800',
        zzzeroPcRPAm1: '5,200',
        zzzeroPcRPAm2: '5,400',
        zzzeroPcRPAm3: '5,200',
        zzzeroPcRPAm4: '45,200',
        zzzeroPcRPAm5: '5,200'
      } */
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    if (this.parentParam !== null) {
      console.log('MSPPI166D')
      this.fn_MakeGrid(this.parentParam)
    }
  },
  watch: {
    lv_SegmentItem (item) {
      // console.log('lv_SegmentItem ===> ', item)
      let idx = this.lv_SegmentItems.find(el => el.key === item).index
      if (isNaN(idx)) {
        this.lv_SelItems = _.cloneDeep(this.lv_Items)
      } else {
        this.lv_SelItems = _.cloneDeep([this.lv_Items[idx]])
      }
      // 탭 변경 시 열려있던 아이템 닫아줌
      if (!_.isEmpty(this.$refs)) {
        for (let expItem in this.$refs) {
          if (this.$refs[expItem].length > 0) {
            this.$refs[expItem].forEach(expItem2 => {
              expItem2.isExpand = false
            })
          }
        }
      }
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_GetClass
    * 설명       : 갱신 주기 유형별 클래스 리턴
    ******************************************************************************/
    fn_GetClass () {
      // let chkCnt = this.lv_SegmentItems.length
      let chkCnt = this.lv_Items.length
      switch(chkCnt) {
        case 2:
        case 5:
        case 8:
          this.lv_SetClass = 'chip-type-wrap chip-ty1'
          break
        case 3:
          this.lv_SetClass = 'chip-type-wrap chip-ty1 chip-btn22'
          break
        case 4:
        case 7:
          this.lv_SetClass = 'chip-type-wrap chip-ty1 chip-btn32'
          break
        // case 5:
        //   return 'chip-type-wrap chip-ty1'
        case 6:
          this.lv_SetClass = 'chip-type-wrap chip-ty1 chip-btn34'
          break
        // case 7:
        //   return 'chip-type-wrap chip-ty1 chip-btn32'
        // case 8:
        //   return 'chip-type-wrap chip-ty1'
      }
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid () {
      let arrAbdacovpac = Gaf.getGafObjByRefId('COVPAC')
      let ZrenwAmtList = []// Main Data

      // 데이터 구성
      for (let k = 0; k < arrAbdacovpac.length; k++) {
        if ((arrAbdacovpac[k].abdacov !== undefined) && arrAbdacovpac[k].abdacov.length > 0) {
          let abdacovList = arrAbdacovpac[k].abdacov
          for (let i = 0; i < abdacovList.length; i++) {
            let rowData = {}
            if (abdacovList[i].aldacov.zzrenwCovScCd === '01') {
              // 갱신주기
              rowData.renwCyl = abdacovList[i].aldacov.zzinprdVl
              rowData.inscdScCd = abdacovList[i].aldacov.zzinscdScCd
              rowData.sortNo = rowData.renwCyl // parseInt(rowData.renwCyl.replace(/,/gi, ''), 10) // 조회순서(갱신주기int)

              // 보험명(계약관점피보험자유형코드)
              if (abdacovList[i].covAbdasubjct[0].zzcontvInsrdCd === '23') {
                rowData.insrNm = '[종피] '
              } else if (abdacovList[i].covAbdasubjct[0].zzcontvInsrdCd === '24') {
                // to-be 자녀 1~3 없음
                // if (abdacovList[i].covAbdasubjct[0].zzchldSnoVl === 2) { // 자녀일련번호
                //   rowData.insrNm = '[자녀2] '
                // } else if (abdacovList[i].covAbdasubjct[0].zzchldSnoVl === 3) {
                //   rowData.insrNm = '[자녀3] '
                // } else {
                //   rowData.insrNm = '[자녀1] '
                // }
              } else {
                rowData.insrNm = '[주피] '
              }
              rowData.insrNm += abdacovList[i].ztpqlCboextAddmAbdacov.zaInsrNm

              if (abdacovList[i].covAbdabase.length > 0) {
                let covAbdabaseList = abdacovList[i].covAbdabase
                let renwPrmList = []
                // let nCnt = 0

                for (let j = 0; j < covAbdabaseList.length; j++) {
                  let rowCavBaseData = {}
                  if (j > 5) break // 최대 5회갱신까지(초회,1회,2회,3회,4회,5회)
                  if (covAbdabaseList[j].pmId === '3590TB5L0000' && covAbdabaseList[j].zzexpnCtgryVl === 301) {
                    rowCavBaseData.zzzeroPcRPAm = piCommonUtil.toChange(covAbdabaseList[j].zzzeroPcRPAm, 'number') // (0퍼센트갱신보험료)
                    renwPrmList.push(rowCavBaseData)
                  }
                }
                rowData.renwPrmList = renwPrmList
              }
              if (rowData.inscdScCd !== '49') ZrenwAmtList.push(rowData) // 보험구분코드 49 예약가입은 출력 안함
            }
          }
        }
      }
      // console.log('[MSPPI166D] makeGrid ZrenwAmtList ===> ', ZrenwAmtList)
      this.fn_SetUpdatedSubtotal(ZrenwAmtList)
    },
    /******************************************************************************
    * Function명 : fn_SetUpdatedSubtotal
    * 설명       : 
    ******************************************************************************/
    fn_SetUpdatedSubtotal (ZrenwAmtListObj) {
      let gridData7 = []
      let gridDataForPublish = []
      let ZrenwAmtList = ZrenwAmtListObj
      // 갱신값 소계 세팅
      let sumPcRPAm0 = 0
      let sumPcRPAm1 = 0
      let sumPcRPAm2 = 0
      let sumPcRPAm3 = 0
      let sumPcRPAm4 = 0
      let sumPcRPAm5 = 0

      ZrenwAmtList = ZrenwAmtList.sort(function (a, b) {
        return Number(a.sortNo) - Number(b.sortNo)
      })

      for (let i = 0; i < ZrenwAmtList.length; i++) {
        let rowCalcData = {}
        // 연수가 같은 것을 통합하기 위해 연수에 따라 list 분배 start====================
        let alreadyHaveYear = false
        for (let k = 0; k < gridDataForPublish.length; ++k) {
          if (gridDataForPublish[k].renwCyl === ZrenwAmtList[i].renwCyl) {
            alreadyHaveYear = true
            break
          }
        }
        if (alreadyHaveYear === false) {
          gridDataForPublish.push({
            renwCyl: ZrenwAmtList[i].renwCyl,
            items: [],
            total: {}
          })
        }
        // 연수가 같은 것을 통합하기 위해 연수에 따라 list 분배 end====================
        rowCalcData.renwCyl = ZrenwAmtList[i].renwCyl // + ' 년' // 갱신주기
        rowCalcData.insrNm = ZrenwAmtList[i].insrNm // 갱신구분
        rowCalcData.sortNo = ZrenwAmtList[i].sortNo // 조회순서(갱신주기int)
        rowCalcData.divSum = 0 // 합계여부(N)

        let renwPrmList = ZrenwAmtList[i].renwPrmList // 갱신값

        if (renwPrmList[0] !== undefined) rowCalcData.zzzeroPcRPAm0 = piCommonUtil.toChange(renwPrmList[0].zzzeroPcRPAm, 'won')
        if (renwPrmList[1] !== undefined) rowCalcData.zzzeroPcRPAm1 = piCommonUtil.toChange(renwPrmList[1].zzzeroPcRPAm, 'won')
        if (renwPrmList[2] !== undefined) rowCalcData.zzzeroPcRPAm2 = piCommonUtil.toChange(renwPrmList[2].zzzeroPcRPAm, 'won')
        if (renwPrmList[3] !== undefined) rowCalcData.zzzeroPcRPAm3 = piCommonUtil.toChange(renwPrmList[3].zzzeroPcRPAm, 'won')
        if (renwPrmList[4] !== undefined) rowCalcData.zzzeroPcRPAm4 = piCommonUtil.toChange(renwPrmList[4].zzzeroPcRPAm, 'won')
        if (renwPrmList[5] !== undefined) rowCalcData.zzzeroPcRPAm5 = piCommonUtil.toChange(renwPrmList[5].zzzeroPcRPAm, 'won')
        if (renwPrmList[0] !== undefined) sumPcRPAm0 += Number(renwPrmList[0].zzzeroPcRPAm.replace(/,/gi, ''))
        if (renwPrmList[1] !== undefined) sumPcRPAm1 += Number(renwPrmList[1].zzzeroPcRPAm.replace(/,/gi, ''))
        if (renwPrmList[2] !== undefined) sumPcRPAm2 += Number(renwPrmList[2].zzzeroPcRPAm.replace(/,/gi, ''))
        if (renwPrmList[3] !== undefined) sumPcRPAm3 += Number(renwPrmList[3].zzzeroPcRPAm.replace(/,/gi, ''))
        if (renwPrmList[4] !== undefined) sumPcRPAm4 += Number(renwPrmList[4].zzzeroPcRPAm.replace(/,/gi, ''))
        if (renwPrmList[5] !== undefined) sumPcRPAm5 += Number(renwPrmList[5].zzzeroPcRPAm.replace(/,/gi, ''))

        gridData7.push(rowCalcData)
        rowCalcData = {}

        // 갱신주기 변경되는 row 정의
        if ( (i === (ZrenwAmtList.length - 1)) || (ZrenwAmtList[i].renwCyl !== ZrenwAmtList[i + 1].renwCyl)) {
          rowCalcData.renwCyl = ZrenwAmtList[i].renwCyl // + ' 년' // 갱신주기
          rowCalcData.insrNm = '갱신보험료 합계' // 갱신구분
          // rowCalcData.insrNm = '합계' // 갱신구분
          rowCalcData.sortNo = ZrenwAmtList[i].sortNo // 조회순서(갱신주기int)
          rowCalcData.divSum = 1 // 합계여부(Y)

          rowCalcData.zzzeroPcRPAm0 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm0, 'number'), 'won')
          rowCalcData.zzzeroPcRPAm1 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm1, 'number'), 'won')
          rowCalcData.zzzeroPcRPAm2 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm2, 'number'), 'won')
          rowCalcData.zzzeroPcRPAm3 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm3, 'number'), 'won')
          rowCalcData.zzzeroPcRPAm4 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm4, 'number'), 'won')
          rowCalcData.zzzeroPcRPAm5 = piCommonUtil.toChange(piCommonUtil.toChange(sumPcRPAm5, 'number'), 'won')

          gridData7.push(rowCalcData)

          sumPcRPAm0 = 0
          sumPcRPAm1 = 0
          sumPcRPAm2 = 0
          sumPcRPAm3 = 0
          sumPcRPAm4 = 0
          sumPcRPAm5 = 0

          // 첫,마지막 row
          if ( i === (ZrenwAmtList.length - 1)) {
            break
          }
        }
      }
      gridData7.forEach(el => {
        for (let i = 0; i < gridDataForPublish.length; ++i) {
          if (el.renwCyl === gridDataForPublish[i].renwCyl) {
            // to-be : 합계 분리 X
            if (!el.insrNm.includes('합계')) {
              gridDataForPublish[i].items.push(el)
            } else {
              gridDataForPublish[i].items.unshift(el)
            }
            // as-is : 합계 분리
            // if (el.insrNm !== '합계') {
            //   gridDataForPublish[i].items.push(el)
            // } else {
            //   gridDataForPublish[i].total = el
            // }
            break
          }
        }
      })
      this.lv_Items = gridDataForPublish
      // 정제된 데이터 갯수에 따라 세그먼트 데이터 구성
      this.$nextTick(() => {
        this.fn_SetSegmentItem()
      })
      // console.log('[MSPPI166D] lv_Items ===> ', this.lv_Items)
    },
    /******************************************************************************
    * Function명 : fn_SetSegmentItem
    * 설명       : 세그먼트 데이터 구성
    ******************************************************************************/
    fn_SetSegmentItem () {
      if (this.lv_Items.length > 1) {
        let addKey = 1
        this.lv_SegmentItems.push({key: `${addKey}`, label: '전체', idx : NaN})
        addKey++
        this.lv_Items.forEach((item, idx) => {
          this.lv_SegmentItems.push({
            key: `${addKey}`,
            index: idx,
            label: `${item.renwCyl}년 갱신`
          })
          addKey++
        })
        this.fn_GetClass()
        this.lv_SegmentItem = '1'
        // console.log('fn_SetSegmentItem complete')
      } else {
        this.lv_SegmentItems = []
        if (this.lv_Items.length === 1) {
          this.lv_SelItems = this.lv_Items
        }
      }
    }
  }
}
</script>