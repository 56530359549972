/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI160M
 * 화면설명: 결과보기(스마트 리포트)
 * 접근권한: 
 * 작 성 일: 2023.02.01
 * 작 성 자: ---
 */
<template>
  <ur-page-container class="msp" title="스마트 리포트" :show-title="false" type="subpage" ref="page" :topButton="true" @on-header-left-click="fn_HistoryBack" @on-scroll="fn_onScroll">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar on">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="45" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow> 
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '스마트 리포트' : '스마트 리포트'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="text_hidden">hidden</div>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <div class="sd-b-Ty1">
              <mo-tab-box :defaultIdx="1" @tab-change="fn_MenuClick" ref="tabbox" class="ns-move-tab-box mt15">
                <template v-for="(item, idx) in lv_TabItems">
                  <mo-tab-content :idx="idx + 1" :key="item.tabComponent" :label="item.tabTitle"></mo-tab-content>
                </template>
              </mo-tab-box>
            </div>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <MSPPI161D v-if="lv_ViewId === 'MSPPI161D'" :parentParam="lv_ParentParam"></MSPPI161D>
            <MSPPI162D v-if="lv_ViewId === 'MSPPI162D'" :parentParam="lv_ParentParam" :isMobileEnv="lv_isMobileEnv"></MSPPI162D>
            <MSPPI163D v-if="lv_ViewId === 'MSPPI163D'" :parentParam="lv_ParentParam" ref="refMSPPI163D"></MSPPI163D>
            <MSPPI164D v-if="lv_ViewId === 'MSPPI164D'" :parentParam="lv_ParentParam"></MSPPI164D>
            <MSPPI165D v-if="lv_ViewId === 'MSPPI165D'" :parentParam="lv_ParentParam"></MSPPI165D>
            <MSPPI166D v-if="lv_ViewId === 'MSPPI166D'" :parentParam="lv_ParentParam"></MSPPI166D>
            <MSPPI167D v-if="lv_ViewId === 'MSPPI167D'" :parentParam="lv_ParentParam"></MSPPI167D>
            <MSPPI168D v-if="lv_ViewId === 'MSPPI168D'" :parentParam="lv_ParentParam"></MSPPI168D>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import GafMainFunc from '@/ui/pi/common/GafMainFunc'
import Gaf from '@/ui/pi/common/Gaf'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import MSPPI161D from '@/ui/pi/MSPPI161D' // 기본정보
import MSPPI162D from '@/ui/pi/MSPPI162D' // 보장내용
import MSPPI163D from '@/ui/pi/MSPPI163D' // 환급금
import MSPPI164D from '@/ui/pi/MSPPI164D' // 실적배당형연금
import MSPPI165D from '@/ui/pi/MSPPI165D' // 연금전환
import MSPPI166D from '@/ui/pi/MSPPI166D' // 갱신보험료
import MSPPI167D from '@/ui/pi/MSPPI167D' // 연금액
import MSPPI168D from '@/ui/pi/MSPPI168D' // 적립전환
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI160M', 
  screenId: 'MSPPI160M',
  components: {
    MSPPI161D,
    MSPPI162D,
    MSPPI163D,
    MSPPI164D,
    MSPPI165D,
    MSPPI166D,
    MSPPI167D,
    MSPPI168D
  },
  data() {
    return {
      name: 'MSPPI160M',
      // 스크롤 대상 컴포넌트 ID
      lv_HeaderviewScrollCompID: '',
      lv_RouteParam: {}, // Tmodel, Pmodel, Vmodel 을 전달받기 위한 변수
      lv_ParentParam: {}, // 각 divison 으로 정보 전달용 변수
      lv_CurrentTabComponent: '',
      lv_TabItems: [],
      lv_ViewId: '',
      lv_selectedIdx: 1,
      lv_isMobileEnv: '' // 스마트폰, 태블릿 환경(T:Tablet, P:Phone, O: other)
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
   ************************************************************************************************/
  // beforeRouteEnter(to, from, next) { 
    // 스마트폰, 태블릿 환경인지 실행
    // piCommonUtil.fn_SetMobileEnv((rslt)=>{
    //   console.log(' >>> piCommonUtil.fn_SetMobileEnv 호출결과 piCommonUtil.fn_IsMobileEnv() = ' + piCommonUtil.fn_IsMobileEnv())
    //   next()
    // })
  // },
  beforeDestroy () {
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
    Gaf.initStore('MSPPI001M')
    GafMainFunc.setStore('MSPPI001M')
  },
  destroyed () {
  },
  async created() {
    this.lv_isMobileEnv = await piCommonUtil.fn_SetMobileEnv()
    this.lv_RouteParam = this.$route.params
    // console.log('[결과보기 메인] ===>\n', JSON.stringify(this.lv_RouteParam), '\n <===')
    Gaf.initStore(this.name)
    Gaf.setProcessNo('1001')
    Gaf.setIfbcSelectionYn('Y') // IFBC 필터링 활성화 = "Y"
    Gaf.addPanelList(this)
    GafMainFunc.setStore(this.name)
    Gaf.setObj({pmodel: this.lv_RouteParam.pmodel, tmodel: this.lv_RouteParam.tmodel})
    
    // this.lv_isMobileEnv = piCommonUtil.fn_IsMobileEnv()
    this.fn_GetServiceData()
  },
  mounted () {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    //footer bottom 영역
    this.$BottomManager.fn_SetBottomVisible(false)

    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    this.$refs.page.setScrollElementId(this.lv_HeaderviewScrollCompID);
    this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.lv_HeaderviewScrollCompID), 'y')  
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_GetServiceData
    * 설명       : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_GetServiceData () {
      window.vue.getStore('progress').dispatch('SHOW')
      let inputJson = this.fn_GetinputJson(this.lv_RouteParam.pmodel, this.lv_RouteParam.tmodel, '50')
      Gaf.execute('MSPPI160M', 'URLPQL08387', inputJson, false, null, this)
    },
    /******************************************************************************
    * Function명 : fn_GetinputJson
    * 설명       : Json 데이터 정제 함수
    ******************************************************************************/
    fn_GetinputJson (Pmodel, Tmodel, ratingType) {
      // INPUT 셋팅
      let pmodelClone = GafMainFunc.copyJson(Pmodel)
      let tmodelClone = GafMainFunc.copyJson(Tmodel)

      // abdabase 삭제
      this.fn_DelRscRstrCalc(pmodelClone)

      // Tmodel 기본세팅
      if (piCommonUtil.isObj(tmodelClone.planHptsDto)) tmodelClone.planHptsDto = null
      if (piCommonUtil.isObj(tmodelClone.entplCovrBfrClPrmOutElem)) tmodelClone.entplCovrBfrClPrmOutElem = null

      tmodelClone.ratingType = ratingType
      return {
        pmodel: pmodelClone,
        tmodel: tmodelClone
      }
    },
    /******************************************************************************
    * Function명 : fn_DelRscRstrCalc
    * 설명       : abdabase 삭제 함수
    ******************************************************************************/
    fn_DelRscRstrCalc (pModel) {
      let arrPmId = [
        '3590TB5L0000', // ExtensionCat>ZLI_ExtTpl_Quo_Prem
        'QUU3TB5L0000', // ExtensionCat>ZLI_ExtTpl_Quo_SmtotPrmByPsgPrd
        'UUU3TB5L0000', // ExtensionCat>ZLI_ExtTpl_Quo_Comm
        'D680000L0000', // Sudi>ZLI_SuDi_Elp_AcuteMISur
        'G680000L0000', // Sudi>ZLI_SuDi_Elp_CerebralHemorrhagSur
        'JB30000L0000', // Sudi>ZLI_SuDi_Elp_CISur
        '0990TB5L0000', // Sudi>ZLI_SuDi_Elp_CISurExch
        'U6B4TB5L0000', // Sudi>ZLI_SuDi_Elp_Death_CISur
        'DT30000J0000', // Sudi>ZLI_SuDi_Elp_DeathSur
        '3990TB5L0000', // Sudi>ZLI_SuDi_Elp_DeathSurExch
        'UZ00000L0000', // Sudi>ZLI_SuDi_Elp_DoubleEntry
        'S9K1000L0000', // Sudi>ZLI_SuDi_Elp_FamilyDoubleEntry
        '5R10000J0000', // Sudi>ZLI_SuDi_Elp_Highprofile
        '5MU4TB5L0000', // Sudi>ZLI_SuDi_Elp_HighprofileDeposit
        'J680000L0000', // Sudi>ZLI_SuDi_Elp_IllnessMedExpSur
        'IAE0000L0000', // Sudi>ZLI_SuDi_Elp_InpatientSur
        '9TA1000L0000', // Sudi>ZLI_SuDi_Elp_Internet
        'L071000L0000', // Sudi>ZLI_SuDi_Elp_LTCSur
        'X890TB5L0000', // Sudi>ZLI_SuDi_Elp_MediBeneficiary
        '7613000L0000', // Sudi>ZLI_SuDi_Elp_Multicontract
        'U890TB5L0000', // Sudi>ZLI_SuDi_Elp_NoClaim
        'M680000L0000', // Sudi>ZLI_SuDi_Elp_TotalDisbltySur
        'P680000L0000', // Sudi>ZLI_SuDi_Elp_TotalMedExpSur
        'RZ00000L0000', // Sudi>ZLI_SuDi_Prod_CorporateExpense
        'WLO4TB5L0000', // Sudi>ZLI_SuDi_Prod_CorporateWelfare
        'Z2Y1000L0000', // Sudi>ZLI_SuDi_Prod_Directdebit
        '80K2000L0000', // Sudi>ZLI_SuDi_Prod_ExistingPolicyholder
        'B0K2000L0000', // Sudi>ZLI_SuDi_Prod_GoodHealth
        'T2Y1000L0000', // Sudi>ZLI_SuDi_Prod_Group
        'LPU2000L0000', // Sudi>ZLI_SuDi_Prod_HighprofileGroup
        '2DV6TB5L0000', // Sudi>ZLI_SuDi_Prod_InverseCalculation
        'R890TB5L0000', // Sudi>ZLI_SuDi_Prod_Kookje
        'W2Y1000L0000', // Sudi>ZLI_SuDi_Prod_PayrollTransfer
        'ZWW1000L0000', // ElpCalcMod>ZLI_ElpCalcMod_Fund_SA_NP
        'WXW1000L0000', // ElpCalcMod>ZLI_ElpCalcMod_GA_NP
        'ZUW1000L0000', // ElpCalcMod>ZLI_ElpCalcMod_GA_P
        'NVW1000L0000', // ElpCalcMod>ZLI_ElpCalcMod_SA_NP
        'BWW1000L0000' // ElpCalcMod>ZLI_ElpCalcMod_SA_P
      ]
      // 구조
      let abdapolicy = pModel.abdapolicy[0] // 현재 Policy 정보
      let abdapolpr = pModel.abdapolicy[0].abdapolpr[0]
      // let abdapolpr = abdapolicy.abdapolpr[0]
      // polprAbdabase 삭제
      let seq = 0
      let aNum = 0

      if (piCommonUtil.isObj(abdapolpr.polprAbdabase) && !piCommonUtil.isEmpty(abdapolpr.polprAbdabase)) {
        let polprAbdabase = abdapolpr.polprAbdabase
        for (let i = 0; i < arrPmId.length; i++) {
          seq = 0
          aNum = polprAbdabase.length
          for (let j = 0; j < aNum; j++) {
            if (polprAbdabase[seq].pmId === arrPmId[i] && polprAbdabase[seq].productBaseId === arrPmId[i]) {
              polprAbdabase.splice(seq, 1)
              seq--
            }
            seq++
          }
        }
      }
      // covAbdabase 삭제
      let abdacovpac = abdapolpr.abdacovpac
      for (let i = 0; i < abdacovpac.length; i++) {
        // console.log(abdacovpac.length, '<==== abdacovpac.length')
        for (let j = 0; j < abdacovpac[i].abdacov.length; j++) {
          // console.log(`${j} covAbdabase obj : ${piCommonUtil.isObj(abdacovpac[i].abdacov[j].covAbdabase)}`)
          if (piCommonUtil.isObj(abdacovpac[i].abdacov[j].covAbdabase) && !piCommonUtil.isEmpty(abdacovpac[i].abdacov[j].covAbdabase)) {
            let covAbdabase = abdacovpac[i].abdacov[j].covAbdabase
            // to-be
            // let filterArr = []
            // filterArr = covAbdabase.filter(item => !arrPmId.includes(item.pmId) && !arrPmId.includes(item.productBaseId))
            // console.log(filterArr, '<=== filterArr')
            for (let k = 0; k < arrPmId.length; k++) {
              seq = 0
              aNum = covAbdabase.length
              for (let l = 0; l < aNum; l++) {
                if (covAbdabase[seq].pmId === arrPmId[k] && covAbdabase[seq].productBaseId === arrPmId[k]) {
                  covAbdabase.splice(seq, 1)
                  seq--
                }
                seq++
              }
            }
            // console.log(covAbdabase, '<=== covAbdabase')
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_GetServiceDataResultHandler
    * 설명       : 서비스 콜백 함수 정의
    ******************************************************************************/
    fn_GetServiceDataResultHandler (result) {
      console.log('fn_GetServiceDataResultHandler START ==================')
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('SUB')
      let tmodel = this.lv_RouteParam.tmodel
      tmodel.planHptsDto = result.tmodel.planHptsDto
      tmodel.entplCovrBfrClPrmOutElem = result.tmodel.entplCovrBfrClPrmOutElem
      this.lv_ParentParam = this.fn_MakeParentParam(result)
      this.fn_MakeTabItems()
      // this.fn_ChangeTabIdx(0)
    },
    /******************************************************************************
    * Function명 : fn_MakeTabItems
    * 설명       : Tab 구성
    ******************************************************************************/
    fn_MakeTabItems () {
      let parentPram = this.lv_ParentParam
      // console.log('parentPram ===> ', parentPram)
      this.lv_TabItems.length = 0
      this.lv_TabItems.push({
        'tabTitle': '기본정보',
        'tabButtonClass': 'st02',
        'tabComponent': 'MSPPI161D'
      })
      this.lv_TabItems.push({
        'tabTitle': '보장내용',
        'tabButtonClass': 'st02',
        'tabComponent': 'MSPPI162D'
      })
      if ( piCommonUtil.isObj(parentPram) ) {
        if ( piCommonUtil.isObj(parentPram.srdvl) && parentPram.srdvl.length > 0 ) {
          let srdvlChk = false
          for (let i = 0; i < parentPram.srdvl.length; i++) {
            if ( piCommonUtil.isObj(parentPram.srdvl[i].srdvlItm) && parentPram.srdvl[i].srdvlItm.length > 0 ) {
              srdvlChk = true
              break
            }
          }
          if (srdvlChk) {
            this.lv_TabItems.push({
              'tabTitle': '환급금',
              'tabButtonClass': 'st02',
              'tabComponent': 'MSPPI163D'
            })
          }
        }
        // ASR200100689_[Sub][P0589] 실적배당연금 결과보기 화면개발 요청(사랑On)
        // 실적배당형연금(TDF)
        // if ( this.isTDF ) {
        //   let planHptsDto = Gaf.getTObj('planHptsDto')
        //   if ( piCommonUtil.isObj(planHptsDto) && piCommonUtil.isObj(planHptsDto.hptsMidBenfAmt) && planHptsDto.hptsMidBenfAmt.length > 0) {
        //     this.lv_ParentParam.planHptsDto = planHptsDto
        //     this.lv_TabItems.push({
        //       'tabTitle': '실적배당형연금',
        //       'tabButtonClass': 'st02',
        //       'tabComponent': 'TSSPI387D'
        //     })
        //   }
        // } else {
        // }
        // 연금액
        if ((piCommonUtil.isObj(parentPram.powerIanutTotRcvExptAmt) && parentPram.powerIanutTotRcvExptAmt.length > 0) ||
          (piCommonUtil.isObj(parentPram.anut) && parentPram.anut.length > 0)
        ) {
          this.lv_TabItems.push({
            'tabTitle': '연금액',
            'tabButtonClass': 'st02',
            'tabComponent': 'MSPPI167D'
          })
        }
        // 연금전환
        if ( piCommonUtil.isObj(parentPram.cnvrAnut) && parentPram.cnvrAnut.length > 0 ) {
          this.lv_TabItems.push({
            'tabTitle': '연금전환',
            'tabButtonClass': 'st02',
            'tabComponent': 'MSPPI165D'
          })
        }
        // ASR200100689_[Sub][P0589] 실적배당연금 결과보기 화면개발 요청(사랑On)
        // 실적배당형연금
        if ( piCommonUtil.isObj(parentPram.anutAddmIlst) &&
          parentPram.anutAddmIlst.length > 0 &&
          (parentPram.anutAddmIlst[0].actulDvdTypAnutIlst !== undefined) &&
          parentPram.anutAddmIlst[0].actulDvdTypAnutIlst.length > 0) {
          this.lv_TabItems.push({
            'tabTitle': '실적배당형연금',
            'tabButtonClass': 'st02',
            'tabComponent': 'MSPPI164D'
          })
        }
        // 갱신보험료
        if ( piCommonUtil.isObj(parentPram.abdacovpac) && parentPram.abdacovpac.length > 0 ) {
          let arrAbdacovpac = parentPram.abdacovpac
          let renwCnt = 0
          // 데이터 조합
          for (let k = 0; k < arrAbdacovpac.length; k++) {
            if (piCommonUtil.isObj(arrAbdacovpac[k].abdacov) && arrAbdacovpac[k].abdacov.length > 0) {
              let abdacovList = arrAbdacovpac[k].abdacov

              for (let i = 0; i < abdacovList.length; i++) {
                // let rowData = {}
                if (abdacovList[i].aldacov.zzrenwCovScCd === '01') {
                  renwCnt++
                }
              }
            }
          }
          if (renwCnt > 0) {
            this.lv_TabItems.push({
              'tabTitle': '갱신보험료',
              'tabButtonClass': 'st02',
              'tabComponent': 'MSPPI166D'
            })
          }
        }
        // 적립전환
        if ( piCommonUtil.isObj(parentPram.acumCnvrSrdvl) && parentPram.acumCnvrSrdvl.length > 0 ) {
          this.lv_TabItems.push({
            'tabTitle': '적립전환',
            'tabButtonClass': 'st02',
            'tabComponent': 'MSPPI168D'
          })
        }
        this.$nextTick(() => {
          // this.lv_selectedIdx = 1
          this.fn_MenuClick(1)
          // this.lv_ViewId = this.lv_TabItems[0].tabComponent
        })
      }
    },
    /******************************************************************************
    * Function명 : fn_MakeParentParam
    * 설명       : 각 division으로 보낼 데이터 필터링
    ******************************************************************************/
    fn_MakeParentParam (result) {
      console.log('fn_MakeParentParam START ====================')
      let abdapolicy = this.lv_RouteParam.pmodel.abdapolicy[0]
      let abdapolpr = abdapolicy.abdapolpr[0]
      let polprAbdaprem = abdapolpr.polprAbdaprem[0]

      let rltnrInfoList = this.lv_RouteParam.tmodel.rltnrInfo
      let prodInfo = this.lv_RouteParam.tmodel.prodInfo
      let baseContDto = this.lv_RouteParam.tmodel.baseContDto

      let rltnrInfo = rltnrInfoList.filter(item => item.zzcontvInsrdCd === '21')
      let gender = '1'.indexOf(rltnrInfo[0].genderCd) >= 0 ? '여' : '남'
      let param = {}
      // =================================
      // 결과보기
      // =================================
      let ageVl = '0'
      if (piCommonUtil.isObj(rltnrInfo[0].ageatentryVl)) ageVl = rltnrInfo[0].ageatentryVl

      param.insrdInfo = rltnrInfo[0].zaInsrdNm + '(' + gender + ',' + ageVl + '세)' // 주피보험자 정보
      param.prdtNm = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPrdtNm // 상품명
      param.productId = prodInfo.prodStructList[0].productId
      param.productVersion = prodInfo.prodStructList[0].productVersion
      // =================================
      // 기본정보
      // =================================
      param.basicInfo = {}

      // 100M 의 vmodel 을 가져와서 사용해야 함
      if (piCommonUtil.isObj(this.lv_RouteParam.vmodel)) {
        console.log('got vmodel pnlpql00109======')
        param.basicInfo = this.lv_RouteParam.vmodel.filter(item => item.isChecked)
      } else {
        param.basicInfo = this.lv_RouteParam.prodInfo.coverageList
      }

      // 202005 외화보험 통화키 추가
      param.basicInfo[0].currencyId = abdapolicy.currencyId

      param.abdacovpac = abdapolpr.abdacovpac
      param.smtotPrm = polprAbdaprem.premaftertaxAm ? polprAbdaprem.premaftertaxAm : '0'// 합계보험료
      param.rlpadPrm = polprAbdaprem.polprAldaprem.zzrlpadPrmAm ? polprAbdaprem.polprAldaprem.zzrlpadPrmAm : '0'// 실납입보험료

      // ASR190600705 (사랑On)태아보험 적립계약 등 추가 화면개발 - 태아보험여부('29')
      if (GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') === '29') {
        param.rlpadPrm = polprAbdaprem.polprAldaprem.zzth02AfSPAm ? polprAbdaprem.polprAldaprem.zzth02AfSPAm : '0'
        param.smtotPrmNm = '출생예정일 이전 합계보험료'
        param.rlpadPrmNm = '출생예정일 이후 합계보험료'
      } else {
        param.smtotPrmNm = '합계보험료'
        param.rlpadPrmNm = '실납입보험료'
      }
      // =================================
      // 보장내용
      // =================================
      param.covrBft = piCommonUtil.isObj(this.lv_RouteParam.tmodel.entplCovrBfrClPrmOutElem) ? this.lv_RouteParam.tmodel.entplCovrBfrClPrmOutElem : ''
      // 해지환급금
      param.ccont = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.ccont : ''
      param.srdvl = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.srdvl : ''
      param.prpadIlstYn = '' // TODO: 선납유무
      param.htpsIlstPtclrTypCd = abdapolpr.aldapolpr.zzentPTCLVl // 가설예시특이유형코드

      param.lgatContAppnCd = '' // 고액계약 X
      for (let i = 0; i < prodInfo.baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
        if (prodInfo.baseInfoDto.gafBaseInfoListDtos[i].code === '00027') {
          if (baseContDto.zzlgatDiscFg === 'Y') {
            param.lgatContAppnCd = 'Y' // 고액계약(고액할인 "Y")
          } else if (baseContDto.zzlgatAcumFg === 'Y') {
            param.lgatContAppnCd = 'N' // 고액계약(고액적립 "N")
          }
          break
        }
      }

      // =================================
      // 갱신에따른변경보험료 [없음]
      // 연금지급액예시
      // =================================
      param.anut = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.anut : ''
      param.powerIanutTotRcvExptAmt = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.powerIanutTotRcvExptAmt : '' // 파워즉시연금

      param.zzanutPymCd = '' // 연금지급유형코드
      param.zzantPymPrdVl = '' // 연금지급보증년수
      param.zzearlCnctYVl = '' // 조기집중년수
      param.zzearlCnctMVl = '' // 조기집중배수
      if (piCommonUtil.isObj(baseContDto.zzanutPymCd)) param.zzanutPymCd = baseContDto.zzanutPymCd // 연금지급유형코드
      if (piCommonUtil.isObj(baseContDto.zzantPymPrdVl)) param.zzantPymPrdVl = baseContDto.zzantPymPrdVl // 연금지급보증년수
      if (piCommonUtil.isObj(baseContDto.zzearlCnctYVl)) param.zzearlCnctYVl = baseContDto.zzearlCnctYVl // 조기집중년수
      if (piCommonUtil.isObj(baseContDto.zzearlCnctMVl)) param.zzearlCnctMVl = baseContDto.zzearlCnctMVl // 조기집중배수

      // 실적배당형연금예시
      param.anutAddmIlst = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.anutAddmIlst : '' // 실적배당연금보증기간코드
      param.zzentplDGPCd = abdapolpr.aldapolpr.zzentplDGPCd // 가입설계실적배당연금보증기간코드
      // 연금전환액예시
      param.cnvrAnut = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.cnvrAnut : ''
      // 적립전환예시
      param.acumCnvrSrdvl = piCommonUtil.isObj(this.lv_RouteParam.tmodel.planHptsDto) ? this.lv_RouteParam.tmodel.planHptsDto.acumCnvrSrdvl : ''

      param.productCode = GafMainFunc.getIfbcValue('ZZPRCD_D_ID') // 상품코드
      // 공통

      // =================================
      // 연금개시연령
      // =================================
      if (piCommonUtil.isObj(baseContDto.zaAnutBgnAge)) {
        param.anutBgnAge = baseContDto.zaAnutBgnAge
      } else {
        for (let i = 0; i < prodInfo.baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
          if (prodInfo.baseInfoDto.gafBaseInfoListDtos[i].displayText === '연금개시연령') {
            param.anutBgnAge = prodInfo.baseInfoDto.gafBaseInfoListDtos[i].defaultValue
          }
        }
      }

      param.ptclrPrdtTypCd = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드
      param.hptsLineCd = this.lv_RouteParam.tmodel.baseContDto.zzentplLineCd // 가설종목코드
      let _mainCov = null
      if (piCommonUtil.isObj(this.lv_RouteParam.vmodel)) {
        _mainCov = this.lv_RouteParam.vmodel.filter(item => item.isChecked)
      } else {
        _mainCov = this.lv_RouteParam.prodInfo.coverageList[0]
      }
      
      if (!piCommonUtil.isObj(_mainCov)) {
        return false
      }
      param.uiCode = _mainCov[0].uiCode // 주보험 가설대표분류코드
      console.log('uiCode ===> ', param.uiCode)
      let htpsReprClsfCd = '' // 가설대표분류
      let IhrtAnutPlus = '0' // 가설대표분류
      if (param.uiCode !== undefined && !piCommonUtil.isEmpty(param.uiCode)) {
        if ('A028401A03NN,A028401A02NN'.indexOf(param.uiCode) > -1) { // 직장인연금
          htpsReprClsfCd = '1'
        } else if ('A034701ANNNN,A034701ANNNN'.indexOf(param.uiCode) > -1) { // 2030저축,자녀교육변액연금
          htpsReprClsfCd = '2'
        } else if ('A032001F03NN,A032001F02NN,A032001A03NN,A032001A02NN,A048101F03NN,A048101F02NN,A048101A03NN,A048101A02NN'.indexOf(param.uiCode) !== -1) { // 상속연금플러스형 유무 판단(가설대표분류코드)
          htpsReprClsfCd = '3'
          IhrtAnutPlus = '1'
        } else {
          htpsReprClsfCd = '3'
        }
      }
      param.htpsReprClsfCd = htpsReprClsfCd
      param.IhrtAnutPlus = IhrtAnutPlus
      param.midDrotYn = 'N' // 중도인출정보
      // 지정인출 초기값 세팅
      param.asgnDrotBgnAge = '0' // 지정인출개시연령

      // GafAppMainDto> pmodel(GafPmodelDto) > abdapolicy(List<GafAbdapolicyDto>) > abdapolpr(GafAbdapolprDto) > polprAbdabase(GafAbdapolprDto)
      if (piCommonUtil.isObj(abdapolpr.polprAbdabase) && abdapolpr.polprAbdabase.length > 0) {
        abdapolpr.polprAbdabase.sort(function (a, b) {
          let x = a.pmId
          let y = b.pmId

          return x === y ? 0 : x < y ? -1 : 1
        })
        abdapolpr.polprAbdabase.sort(function (a, b) {
          let x = a.zzprpslMaxSCd
          let y = b.zzprpslMaxSCd

          if (x < y) {
            return -1
          } else if (x > y) {
            return 1
          } else {
            x = parseInt(a.zzpsgYrTt, 10)
            y = parseInt(b.zzpsgYrTt, 10)
            return x === y ? 0 : x < y ? -1 : 1
          }
        })

        let prdt = abdapolpr.polprAbdabase
        let prdtLen = prdt.length
        let PM_ID_9590TB5L0000 = '9590TB5L0000' // ZLI_Ext_Quo_PadPln_Prod 자유납,추가납,중도인출 In
        let PM_ID_FKO4TB5L0000 = 'FKO4TB5L0000' // ZLI_ExtCat_Quo_Info 자동감액, 적립전환, 지정인출 In

        if (prdtLen > 0) {
          for (let i = 0; i < prdtLen; i++) {
            let polprAbdabase = abdapolpr.polprAbdabase[i]
            let pmId = polprAbdabase.pmId
            if (pmId === PM_ID_9590TB5L0000) {
              if (polprAbdabase.zzadpadInfoCd === '05') { // 중도인출정보
                param.midDrotYn = 'Y' // 중도인출정보
              }
            }
            if (pmId === PM_ID_FKO4TB5L0000) { // 연금선지급, 적립전환
              // 지정인출
              if (!piCommonUtil.isEmpty(polprAbdabase.zzasgnDrBAVl) && parseInt(polprAbdabase.zzasgnDrBAVl, 10) > 0) param.asgnDrotBgnAge = polprAbdabase.zzasgnDrBAVl // 지정인출개시연령
            }
          }
        }
      }

      return param
      // Gaf.d('PNLPQL00110 To PNLPQL10949 param => ', param)
      // if (piCommonUtil.isObj(Gaf.getTObj('planHptsDto'))) {
      //   resultPanel.fnOpen(oPanel['l00110_PNLPQL10949_Callback'], param)
      // }
    },
    /******************************************************************************
    * Function명 : URLPQL08387_LocalCallBack
    * 설명       : URLPQL08387 callback 함수
    ******************************************************************************/
    URLPQL08387_LocalCallBack (returnData) {
      console.log('URLPQL08387_LocalCallBack 도착 ================')
      this.fn_GetServiceDataResultHandler(returnData)
    },
    /******************************************************************************
    * Function명 : fn_MenuClick
    * 설명       : 메뉴 선택 시 함수
    ******************************************************************************/
    fn_MenuClick (item) {
      this.lv_ViewId = this.lv_TabItems[item - 1].tabComponent

      if(this.lv_ViewId === 'MSPPI163D'){ //환급금
        this.$nextTick(() => {
          this.$refs.refMSPPI163D.fn_showButtonInit()
        })
      }
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      let vm = this
      vm.$router.go(-1)
      this.$destroy()
    },

    fn_onScroll(e) {
      if(this.lv_ViewId === 'MSPPI163D' && this.$refs.refMSPPI163D.radioStyle === '2' && this.$refs.refMSPPI163D.showButton === true){ //환급금
        console.log('fn_onScroll >> ', e.target.scrollTop)
        if(e.target.scrollTop > 0) {
          this.$refs.refMSPPI163D.showButton = false
        }
      }
      
    }
  }
}
</script>