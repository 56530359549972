/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI165D
 * 화면설명: 결과보기(스마트 리포트) > 연금전환
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
 <template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap mb30"
                      v-if="lv_Items.length > 0">
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt30">  
        <div class="full">
          <b class="fs18rem fexInt pal0">경과기간 및 연금보증 유형에 따른 연금 연액</b>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt10">
        <ur-box-container alignV="start" componentid="" direction="column" class="round-box pt13 pb12 bgcolor-1">
          <!-- 투자수익형 이율 START -->
          <div v-if="lv_Toojasooik" class="full fs17rem txt-center">
            종신연금플러스형 <b class="ml3">20회 보증,</b><br/>
            <b>{{ lv_SubjectTitle }}%</b> 기준
          </div>
          <!-- 투자수익형 END -->
          <!-- 금리연동형 이율 START -->
          <div v-else class="full fs17rem txt-center">
            기준이율 : <b>{{ lv_SubjectTitle }}%</b>
          </div>
          <!-- 금리연동형 END -->
        </ur-box-container>
      </ur-box-container>

      <!-- ============== 투자수익형 START ============== -->
      <template v-if="lv_Toojasooik">
        <!-- 해약환급금/연금액 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt30 mb10">
          <mo-segment-wrapper solid primary v-model="lv_HeaderItem">
            <mo-segment-button value="1">해약환급금</mo-segment-button>
            <mo-segment-button value="2">연금액</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30">
          <!-- 투자 수익률 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area con-area">   
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="lv_HeaderItemSub" class="chip-type-wrap chip-ty1">
                <mo-segment-button v-for="(subItem, idx2) in lv_HeaderItems" :key="idx2" :value="subItem.key">{{ subItem.label }}% 가정</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </ur-box-container>
          <!-- List -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20">
            <div class="full txt-right"><span class="crTy-bk7">(단위:만원)</span></div>
            <div class="table-area mt10">
              <table :class="lv_HeaderItem === '1' ? 'table row-type' : 'table row-type chart-type'">
                <colgroup>
                  <col width="29%" />
                  <col width="26%" />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="2">연금전환시점</th>
                    <th rowspan="2" v-if="lv_HeaderItem === '1'">해약환급금<br/>(전환적립액)</th>
                    <th rowspan="2" v-if="lv_HeaderItem === '2'">연금액<br/>(전환적립액)</th>
                  </tr>
                  <tr>
                    <th>경과기간</th>
                    <th class="bd-r-Ty1">나이(세)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in lv_Items" :key="idx">
                    <td class="txt-center">{{ item.psgYr }}</td>
                    <td class="txt-center">{{ item.age }}</td>
                    <td class="pr10 txt-right" v-if="lv_HeaderItem === '1'">{{ fn_NumberWithCommas(item.content1[lv_HeaderSubIdx], '') }}</td>
                    <td class="pr10 txt-right" v-if="lv_HeaderItem === '2'">
                      <div class="bar-chart">
                        <div class="fill-bar" :style="{width: fn_GetWidth(item.content2[lv_HeaderSubIdx])+'%'}"></div> <!--:style="width:"-->
                      </div>
                      <span class="count">{{ fn_NumberWithCommas(item.content2[lv_HeaderSubIdx], '') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> 
          </ur-box-container>
        </ur-box-container>
      </template>
      <!-- ============== 투자수익형 END ============== -->

      <!-- ============== 금리연동형 START ============== -->
      <!-- 경과기간 -->
      <template v-if="!lv_Toojasooik">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pa2024 pt30">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_HeaderItem" :class="lv_SetClass">
              <mo-segment-button v-for="(item, idx) in lv_HeaderItems2" :key="idx" :value="item.key">{{ item.label }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="round-box pt13 pb12 bgcolor-blue">
            <div class="full fs17rem txt-center">
              <b>{{ lv_HeaderItems2[lv_HeaderIdx].label }}</b>, 연금준비금 <b>{{ fn_NumberWithCommas(lv_HeaderItems2[lv_HeaderIdx].anutPymBgnPntmAcumAmt, '원') }}</b>
            </div>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20">
          <div class="table-area">
            <table class="table row-type chart-type">
              <colgroup>
                <col width="29%" />
                <col width="26%" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>연금유형</th>
                  <th>지급형태</th>
                  <th>연금액(단위:만원)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in lv_Items" :key="idx">
                  <td class="txt-center pl9 pr9" v-if="item.isFirst && !item.reserveFund" :rowspan="item.cnt">{{ item.arg1 }}</td>
                  <td v-if="!item.reserveFund" class="txt-center">{{ item.arg2 }}</td>
                  <td v-if="!item.reserveFund" class="pr10 txt-right">
                    <div class="bar-chart">
                      <div class="fill-bar" :style="{width: fn_GetWidth(item.arg3[lv_HeaderIdx])+'%'}"></div> <!--:style="width:"-->
                    </div>
                    <span class="count">{{ fn_NumberWithCommas(item.arg3[lv_HeaderIdx], '') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> 
        </ur-box-container>
      </template>
      <!-- ============== 금리연동형 END ============== -->
    </ur-box-container>
    <!-- NoData 영역: start  -->
    <ur-box-container v-if="lv_Items.length === 0" alignV="start" componentid="ur_box_container_002" direction="column" class="no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- NoData 영역: end  -->
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI165D',
  screenId: 'MSPPI165D',
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    parentParam: Object
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      lv_Toojasooik: true, // 투자수익형, 금리연동형 구분하여 화면구성이 달라짐
      lv_SubjectTitle: '', // 제목
      lv_HeaderItems: [], // 투자수익형일 경우 Table header
      lv_HeaderItemsSub: [], // 투자수익형일 경우 Table Sub header
      lv_HeaderItems2: [], // 금리연동형일 경우 Table header
      lv_Items: [],
      lv_MaxValue: 0,
      lv_HeaderItem: '',
      lv_HeaderItemSub: '', // 투자수익형일 경우 Table Sub Select Header
      lv_HeaderIdx: 0,
      lv_HeaderSubIdx: 0,
      lv_SetClass: ''
    }
  },//data
  created () {
    if (this.parentParam !== null) {
      console.log('BindData_MSPPI165D')
      this.fn_MakeGrid(this.parentParam)
    }
  },//created
  mounted () {
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    lv_HeaderItem (item) {
      if (!_.isEmpty(item)) {
        this.lv_HeaderIdx  = item - 1
        // console.log('watch lv_HeaderItem ===> lv_HeaderIdx : ', this.lv_HeaderIdx)
        if (this.lv_Toojasooik && this.lv_HeaderItemSub === '') {
          this.lv_HeaderItemSub = '1'
        }
      }
    },
    lv_HeaderItemSub (item) {
      if (!_.isEmpty(item)) {
        this.lv_HeaderSubIdx = item - 1
      }
    }
  },//watch

  computed: {},//computed

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    fn_SetClass () {
      let chkCnt = this.lv_HeaderItems2.length
      switch (chkCnt) {
        case 1:
        case 2:
        case 3:
          this.lv_SetClass = 'chip-type-wrap chip-ty1'
          break
        case 4:
          this.lv_SetClass = 'chip-type-wrap chip-ty1 chip-btn22'
          break
        case 5:
          this.lv_SetClass = 'chip-type-wrap chip-ty1 chip-btn32'
          break

      }
    },
    fn_NumberWithCommas (number, unit) {
      if (number === 0) return '-'
      return (number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit)
    },
    fn_GetWidth (v) {
      return 100 * v / this.lv_MaxValue
    },
    /******************************************************************************
    * Function명 : fn_SetTableHeader
    * 설명       : 금리연동형 헤더 데이터 세팅 함수
    ******************************************************************************/
    fn_SetTableHeader (irtTypCd, rowData, idx) { // 임시로 금리연동형만 해당됨
      let rightTitleValue = {}
      if (irtTypCd === '04') { // 투자수익형
      } else { // 금리연동형
        rightTitleValue.key = `${idx + 1}`
        rightTitleValue.label = rowData.psgYr + '년' + '(' + rowData.age + '세)'
      }
      this.lv_HeaderItems2.push(rightTitleValue)
    },
    /******************************************************************************
    * Function명 : fn_SetTableHeader2
    * 설명       : 투자수익률 헤더 데이터 세팅 함수
    ******************************************************************************/
    fn_SetTableHeader2 (element, idx) {
      // console.log('fn_SetTableHeader2 ===> ', element)
      this.lv_HeaderItems.push({
        label: piCommonUtil.toChange(element, 'percent',3), // 투자수익률
        key: `${idx + 1}`
      })
      this.lv_HeaderItemsSub.push({
        label: '해약환급금',
        sublabel: '(전환적립액)',
        key: this.lv_HeaderItemsSub.length
      })
      this.lv_HeaderItemsSub.push({
        label: '연금액',
        sublabel: '(현공시이율)',
        key: this.lv_HeaderItemsSub.length
      })
    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentPramObj) {
      let parentPram = parentPramObj
      let irtTypCd = '' // 이율유형코드
      let ZAnutRcvIrtDto = [] // Main Data
      // 데이터

      // Table header, 및 제목 설정
      for (let i = 0; i < parentPram.cnvrAnut.length; i++) {
        let el = parentPram.cnvrAnut[i]
        let rowData = {}
        // title
        rowData.psgYr = el.psgYr // 경과기간
        rowData.age = el.age // 나이
        // =============================================================
        // Header Setting
        // =============================================================
        if (( el.anut !== undefined ) && el.anut.length > 0 ) {
          for (let j = 0; j < el.anut.length; j++) {
            // 투자수익형일 경우 table header 설정
            if (i === 0) {
              this.fn_SetTableHeader2(el.anut[j].lvyIrt, j)
            }
            // 제목설정(이율유형코드별)
            if (i === 0) {
              // irtTypCd = rowData.irtTypCd
              let lvyIrt1 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].lvyIrt, 'percent', 2)
              irtTypCd = el.anut[j].irtTypCd
              if (irtTypCd === '04') { // 투자수익형
                this.lv_SubjectTitle = '연복리 ' + lvyIrt1
                // this.lv_SubjectTitle = '종신연금플러스형 20회보증, 연복리 ' + lvyIrt1
              } else if (irtTypCd === '02') { // 금리연동형
                this.lv_SubjectTitle = '연복리 ' + lvyIrt1
              }
            }
          }
          // 우측타이틀 정보 설정
          this.fn_SetTableHeader(irtTypCd, rowData, i)
        }
        // End of header ===============================================
      }
      // =============================================================
      // Grid Data Setting
      // =============================================================
      for (let i = 0; i < parentPram.cnvrAnut.length; i++) {
        let el = parentPram.cnvrAnut[i]
        let rowData = {}
        // let strLvyIrt1 = '' // left ratio subject
        // title
        rowData.psgYr = el.psgYr // 경과기간
        rowData.age = el.age // 나이
        // row data
        if (( el.anut !== undefined ) && el.anut.length > 0 ) {
          let lvyIrtArry = []
          for (let j = 0; j < el.anut.length; j++) {
            let record = {}
            record.anutPymBgnPntmAcumAmt = el.anut[j].anutPymBgnPntmAcumAmt // 해지환급금

            // 이율유형코드가 02(공시이율),04(투자수익률)의 경우만 설정한다.
            // 보험종형코드가 '2030저축용1종,2종(L1,L2)'의 경우,생활자금수령이 '수령(Y)'일때 예시한다.
            if (el.anut[j].diseSurgOccuYn === 'N' &&
              (el.anut[j].irtTypCd === '02' || el.anut[j].irtTypCd === '04') &&
              (el.anut[j].lvngFdRcvYn === 'N' || (parentPram.productCode.substring(0, 6) === 'LP0347' && el.anut[j].lvngFdRcvYn === 'Y'))
            ) {
              if ((el.anut[j].anutRcvIrt !== undefined) && el.anut[j].anutRcvIrt.length > 0 ) {
                rowData.anutBgnAge = parentPram.anutBgnAge // 연금개시연령
                rowData.lvyIrt = el.anut[j].lvyIrt // 연금지급개시전부리이율
                rowData.irtTypCd = el.anut[j].irtTypCd // 이율유형코드
                rowData.diseSurgOccuYn = el.anut[j].diseSurgOccuYn // 질병수술발생여부
                rowData.lvngFdRcvYn = el.anut[j].lvngFdRcvYn // 생활자금수령여부
                rowData.anutPymBgnPntmAcumAmt = el.anut[j].anutPymBgnPntmAcumAmt // 연금개시시점에 계약자 적립금(해지환급금)

                rowData.lvyIrt1 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].lvyIrt, 'percent', 2)
                // rowData.lvyIrt1 = this.toPercent(el.anut[j].anutRcvIrt[0].lvyIrt, 2)
                rowData.irtTypCd1 = el.anut[j].anutRcvIrt[0].irtTypCd

                if (el.anut[j].irtTypCd !== '04' && rowData.irtTypCd1 === '02') { // 금리연동형
                  // record.wlifePlusAmt20 = this.toManWon(el.anut[j].anutRcvIrt[0].wlifePlusAmt20) // 20회 보증
                  // 종신연금플러스형
                  rowData.wlifePlusAmt10 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].wlifePlusAmt10, 'unit') // 10회 보증
                  rowData.wlifePlusAmt20 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].wlifePlusAmt20, 'unit') // 20회 보증
                  rowData.wlifePlusAmt30 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].wlifePlusAmt30, 'unit') // 30회 보증
                  rowData.wlifePlusAmt00 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].wlifePlusAmt00, 'unit') // 100세 보증
                  // 확정기간연금플러스형
                  rowData.dcdPlusAmt05 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt05, 'unit') // 5년형
                  rowData.dcdPlusAmt10 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt10, 'unit') // 10년형
                  rowData.dcdPlusAmt15 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt15, 'unit') // 15년형
                  rowData.dcdPlusAmt20 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt20, 'unit') // 20년형
                  rowData.dcdPlusAmt25 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt25, 'unit') // 25년형
                  rowData.dcdPlusAmt30 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dcdPlusAmt30, 'unit') // 30년형
                  // 상속연금형
                  rowData.survIhrtAnutAmt = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].survIhrtAnutAmt, 'unit') // 생존시
                  rowData.dthLamntIhrtAnutAmt = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].dthLamntIhrtAnutAmt, 'unit') // 해지 or 사망시 일시금
                } else if (rowData.irtTypCd1 === '04') { // 금리연동형 => 투자수익형
                  // if(el.anut[j].anutWrkex[j]
                  // record.wlifePlusAmt20 = this.toManWon(el.anut[j].anutRcvIrt[0].wlifePlusAmt20) // 종신연금플러스형 20회 보증
                }
                record.wlifePlusAmt20 = piCommonUtil.toChange(el.anut[j].anutRcvIrt[0].wlifePlusAmt20, 'unit') // 종신연금플러스형 20회 보증
              }
            }
            lvyIrtArry.push(record)
          }
          rowData.lvyIrtArry = lvyIrtArry
          // // 우측타이틀 정보 설정
          // this.fn_SetTableHeader(irtTypCd, rowData)
        }
        ZAnutRcvIrtDto.push(rowData)
      }
      // console.log(ZAnutRcvIrtDto, '연금전환 - ZAnutRcvIrtDto(1차 가공 데이터)')
      // this.fn_SetClass()
      this.fn_MakeRowDataAndBind(irtTypCd, ZAnutRcvIrtDto)
    },
    /******************************************************************************
    * Function명 : fn_MakeRowDataAndBind
    * 설명       : 유형별 데이터 세팅 함수
    ******************************************************************************/
    fn_MakeRowDataAndBind (irtTypCd, ZAnutRcvIrtDto) {
      let rows = []
      if (piCommonUtil.isObj(ZAnutRcvIrtDto) && ZAnutRcvIrtDto.length > 0) {
        // 투자수익형일 경우 (이율유형코드 === 04)
        if (irtTypCd === '04') {
          rows = this.fn_BindROI(ZAnutRcvIrtDto)
        // 금리연동형인 경우
        } else {
          this.fn_SetClass()
          rows = this.fn_BindInterestLinkedType(ZAnutRcvIrtDto)
        }
        this.lv_Items = rows
        this.lv_HeaderItem = '1'
        // console.log(rows, '연금전환 - rows(2차 가공데이터)')
      }
    },
    /******************************************************************************
    * Function명 : fn_BindROI
    * 설명       : 투자수익형 모델 binding
    ******************************************************************************/
    fn_BindROI (ZAnutRcvIrtDto) {
      let rows = []
      let rowNum = 0
      this.lv_Toojasooik = true
      // 행 생성
      for (let i = 0; i < ZAnutRcvIrtDto.length; i++) {
        let row = {}
        rows.push(row)
      }

      this.lv_MaxValue = 0
      for (let obj in ZAnutRcvIrtDto) {
        rows[rowNum].psgYr = ZAnutRcvIrtDto[obj].psgYr + '년' // 경과기간
        rows[rowNum].age = ZAnutRcvIrtDto[obj].age + '세' // 나이
        // rows[rowNum].content = []
        rows[rowNum].content1 = [] // 해약환급금
        rows[rowNum].content2 = [] // 연금액
        for ( let j = 0; j < ZAnutRcvIrtDto[obj].lvyIrtArry.length; j++) {
          // 해약환급금
          rows[rowNum].content1.push(
            parseInt(piCommonUtil.toChange(ZAnutRcvIrtDto[obj].lvyIrtArry[j].anutPymBgnPntmAcumAmt / 10000, 'number')) // 해지환급금
          )
          // 연금액
          rows[rowNum].content2.push(
            ZAnutRcvIrtDto[obj].lvyIrtArry[j].wlifePlusAmt20 // 종신연금플러스형 20회보증
          )
          this.lv_MaxValue = Math.max(this.lv_MaxValue, (ZAnutRcvIrtDto[obj].lvyIrtArry[j].wlifePlusAmt20)) // 종신연금플러스형 20회보증 최댓값
        }
        rowNum++
      }
      return rows
    },
    /******************************************************************************
    * Function명 : fn_BindInterestLinkedType
    * 설명       : 금리연동형 모델 binding
    ******************************************************************************/
    fn_BindInterestLinkedType (ZAnutRcvIrtDto) {
      let rows = []
      let rowNum = 0
      this.lv_Toojasooik = false
      // 1.row 생성
      // rowCount 계산(각 행 존재여부 확인)
      // 전환전 계약의 연금준비금
      let rowCount = 0
      let tot_anutPymBgnPntmAcumAmt = 0
      let tot_wlifePlusAmt10 = 0
      let tot_wlifePlusAmt20 = 0
      let tot_wlifePlusAmt30 = 0
      let tot_wlifePlusAmt00 = 0
      let tot_dcdPlusAmt05 = 0
      let tot_dcdPlusAmt10 = 0
      let tot_dcdPlusAmt15 = 0
      let tot_dcdPlusAmt20 = 0
      let tot_dcdPlusAmt25 = 0
      let tot_dcdPlusAmt30 = 0
      let tot_survIhrtAnutAmt = 0
      let tot_dthLamntIhrtAnutAmt = 0

      // for (let obj in ZAnutRcvIrtDto) {
      ZAnutRcvIrtDto.forEach(el => {
        // 전환전 계약의 연금준비금
        tot_anutPymBgnPntmAcumAmt += el.anutPymBgnPntmAcumAmt
        tot_wlifePlusAmt10 += el.wlifePlusAmt10
        tot_wlifePlusAmt20 += el.wlifePlusAmt20
        tot_wlifePlusAmt30 += el.wlifePlusAmt30
        tot_wlifePlusAmt00 += el.wlifePlusAmt00
        tot_dcdPlusAmt05 += el.dcdPlusAmt05
        tot_dcdPlusAmt10 += el.dcdPlusAmt10
        tot_dcdPlusAmt15 += el.dcdPlusAmt15
        tot_dcdPlusAmt20 += el.dcdPlusAmt20
        tot_dcdPlusAmt25 += el.dcdPlusAmt25
        tot_dcdPlusAmt30 += el.dcdPlusAmt30
        tot_survIhrtAnutAmt += el.survIhrtAnutAmt
        tot_dthLamntIhrtAnutAmt += el.dthLamntIhrtAnutAmt
      })
      if (tot_anutPymBgnPntmAcumAmt > 0) rowCount++
      if (tot_wlifePlusAmt10 > 0) rowCount++
      if (tot_wlifePlusAmt20 > 0) rowCount++
      if (tot_wlifePlusAmt30 > 0) rowCount++
      if (tot_wlifePlusAmt00 > 0) rowCount++
      if (tot_dcdPlusAmt05 > 0) rowCount++
      if (tot_dcdPlusAmt10 > 0) rowCount++
      if (tot_dcdPlusAmt15 > 0) rowCount++
      if (tot_dcdPlusAmt20 > 0) rowCount++
      if (tot_dcdPlusAmt25 > 0) rowCount++
      if (tot_dcdPlusAmt30 > 0) rowCount++
      if (tot_survIhrtAnutAmt > 0) rowCount++
      if (tot_dthLamntIhrtAnutAmt > 0) rowCount++

      for (let i = 0; i < rowCount; i++) {
        let row = {}
        rows.push(row)
      }

      // 기존 ep 소스의 반복적인 작업을 간소화함
      let mapppingInfo = {
        tot_anutPymBgnPntmAcumAmt: {arg1: '전환전 계약의 전환적립액', arg2: '전환전 계약의 전환적립액', value: tot_anutPymBgnPntmAcumAmt},
        tot_wlifePlusAmt10: {arg1: '종신연금플러스형', arg2: '10회 보증', value: tot_wlifePlusAmt10},
        tot_wlifePlusAmt20: {arg1: '종신연금플러스형', arg2: '20회 보증', value: tot_wlifePlusAmt20},
        tot_wlifePlusAmt30: {arg1: '종신연금플러스형', arg2: '30회 보증', value: tot_wlifePlusAmt30},
        tot_wlifePlusAmt00: {arg1: '종신연금플러스형', arg2: '100세 보증', value: tot_wlifePlusAmt00},
        tot_dcdPlusAmt05: {arg1: '확정기간연금플러스형', arg2: '5년형', value: tot_dcdPlusAmt05},
        tot_dcdPlusAmt10: {arg1: '확정기간연금플러스형', arg2: '10년형', value: tot_dcdPlusAmt10},
        tot_dcdPlusAmt15: {arg1: '확정기간연금플러스형', arg2: '15년형', value: tot_dcdPlusAmt15},
        tot_dcdPlusAmt20: {arg1: '확정기간연금플러스형', arg2: '20년형', value: tot_dcdPlusAmt20},
        tot_dcdPlusAmt25: {arg1: '확정기간연금플러스형', arg2: '25년형', value: tot_dcdPlusAmt25},
        tot_dcdPlusAmt30: {arg1: '확정기간연금플러스형', arg2: '30년형', value: tot_dcdPlusAmt30},
        tot_survIhrtAnutAmt: {arg1: '상속연금형', arg2: '생존시', value: tot_survIhrtAnutAmt},
        tot_dthLamntIhrtAnutAmt: {arg1: '상속연금형', arg2: '해지 or 사망시 일시금', value: tot_dthLamntIhrtAnutAmt}
      }

      // max 값 찾기
      this.lv_MaxValue = 0
      for (let obj in mapppingInfo) {
        if (mapppingInfo[obj].value > 0) {
          rows[rowNum]['arg1'] = mapppingInfo[obj].arg1
          rows[rowNum]['arg2'] = mapppingInfo[obj].arg2
          rows[rowNum]['arg3'] = []
          let objNm = String(obj).slice(4)
          if (objNm === 'anutPymBgnPntmAcumAmt') rows[rowNum].reserveFund = true
          else rows[rowNum].reserveFund = false
          for (let objInner in ZAnutRcvIrtDto) {
            rows[rowNum]['arg3'].push((ZAnutRcvIrtDto[objInner][objNm]))
            if (String(obj) !== 'tot_anutPymBgnPntmAcumAmt') {
              this.lv_MaxValue = Math.max(this.lv_MaxValue, ZAnutRcvIrtDto[objInner][objNm])
            }
          }
          rowNum++
        }
        // 맨 앞의 분류 구분을 위한 First 설정
        let diffTitle = ''
        for (let i = 0; i < rows.length; ++i) {
          if (rows[i].arg1 !== diffTitle) {
            rows[i].isFirst = true
            diffTitle = rows[i].arg1
          } else {
            rows[i].isFirst = false
          }
        }
        // 맨 앞의 분류 구분을 위한 유형별 갯수 세기
        for (let i = 0; i < rows.length; ++i) {
          let cnt = 0
          if (rows[i].isFirst === true) {
            for (let j = i; j < rows.length; ++j) {
              if (rows[i].arg1 === rows[j].arg1) cnt++
            }
            rows[i].cnt = cnt
          }
        }
      }
      // ====================================
      let inputHeader = rows[0]
      inputHeader.arg3.forEach((item, idx) => {
        this.lv_HeaderItems2[idx].anutPymBgnPntmAcumAmt = item
      })
      return rows.splice(1)
      // return rows
    },
 },// methods

}//export default
</script>