/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI164D
 * 화면설명: 결과보기(스마트 리포트) > 실적배당형연금
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
 <template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt30">  
        <div class="full">
          <b class="fs18rem fexInt pal0">투자수익률 및 나이에 따른 연금 연액</b>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pa2024 pt10">   
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="lv_HeaderItem" class="chip-type-wrap chip-ty1" @input="fn_SegmentBoxClicked">
            <mo-segment-button v-for="(item, idx) in lv_HeaderItems" :key="idx" :value="item.key">{{ item.label }}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="round-box bgcolor-blue">
          <div class="full fexTy3">
            <span class="fs14rem crTy-bk7">연금지급 개시시점</span>
            <b class="fs17rem txt-right">{{ lv_HeaderItems[lv_HeaderItem-1].age }}세</b>
          </div>
          <div class="full fexTy3 mt10">
            <span class="fs14rem crTy-bk7">계약자적립액</span>
            <b class="fs17rem txt-right">{{ lv_HeaderItems[lv_HeaderItem-1].anutFrscAmt }}원</b>
          </div>
        </ur-box-container>
      </ur-box-container>
      
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20 pb0">
        <ul class="terms-list-area fs14rem">
          <li><span class="crTy-bk7">{{ lv_InfoMessage }}</span></li>
        </ul> 
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt10">
        <div class="table-area">
          <table class="table row-type">
            <colgroup>
              <col width="50%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>나이</th>
                <th>연금액(단위:만원)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!isTDF" v-for="(item, idx) in lv_Items" :key="idx">
                <td class="txt-center">{{ item.age }}</td>
                <td class="pr10 txt-right">{{ item[`arg${lv_HeaderItem}`] }}</td>
              </tr>
            </tbody>
          </table>
        </div> 
      </ur-box-container>  
    </ur-box-container>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Gaf from '@/ui/pi/common/Gaf.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI164D',
  screenId: 'MSPPI164D',
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    parentParam: Object
  }, // parents data

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      lv_InfoMessage: '',
      lv_HeaderItems: [],
      lv_Items: [],
      // TDF 필요한 변수 - 23/01 ver. [판매중지] 상태
      isTDF: false, // TDF 상품여부
      lv_ItemsTDF: [],
      lv_HeaderItemsSub: [],
      lv_HeaderItem: ''
    }
  },//data

  created () {
    if (this.parentParam !== null) {
      // console.log('[MSPPI164D] ====> ', this.parentParam)
      this.fn_MakeGrid(this.parentParam)
    }
  },//created
  mounted () {
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    slctTabIdx(){
      // console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
  },//watch

  computed: {},//computed

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SegmentBoxClicked
    * 설명       : 헤더 세그먼트 선택 시 함수
    ******************************************************************************/
    fn_SegmentBoxClicked (item) {

    },
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentPramObj) {
      let entSrnEpId = '' // 가설화면예외코드(ZZENT_SRN_EP_ID) - 10 TDF변액연금
      let prodInfo = Gaf.getTObj('prodInfo')
      if (prodInfo.baseInfoDto.zzentSrnEpId !== undefined && prodInfo.baseInfoDto.zzentSrnEpId !== 'undefined') {
        entSrnEpId = prodInfo.baseInfoDto.zzentSrnEpId
      }
      // console.log('[MSPPI164D] fn_MakeGrid entSrnEpId : ', entSrnEpId)
      if (entSrnEpId.indexOf('11') > -1) { // TDF변액연금 일때
        this.isTDF = true
        this.fn_TDFFilterData(parentPramObj)
      } else {
        this.isTDF = false
        this.fn_FilterData(parentPramObj)
      }
      console.log('BindData_MSPPI164D, isTDF:' + this.isTDF)
    },
    /******************************************************************************
    * Function명 : fn_TDFFilterData
    * 설명       : TDF 변액상품용 bind data
    ******************************************************************************/
    fn_TDFFilterData (parentPramObj) {
      let planHptsDto = parentPramObj
      let actulDvdTypAnutIlst = []
      if (!piCommonUtil.isEmpty(planHptsDto) && piCommonUtil.isObj(planHptsDto) &&
       piCommonUtil.isObj(planHptsDto.anutAddmIlst) && planHptsDto.anutAddmIlst.length > 0 &&
       piCommonUtil.isObj(planHptsDto.anutAddmIlst[0].actulDvdTypAnutIlst) && planHptsDto.anutAddmIlst[0].actulDvdTypAnutIlst.length > 0) {
        actulDvdTypAnutIlst = planHptsDto.anutAddmIlst[0].actulDvdTypAnutIlst
      } else {
        console.log('MSPPI164D => TDF planHptsDto Data 없음!')
        return
      }
      if (actulDvdTypAnutIlst === null) {
        return
      }

      // 테이블 데이터 정리
      let nCnt1 = 0 // 데이터 개수(열)
      let arrTypLst = []
      let rowData = []

      let actulDvdTypAnutIlstlen = actulDvdTypAnutIlst.length
      for (let i = 0; i < actulDvdTypAnutIlstlen; i++) {
        if (piCommonUtil.isObj(actulDvdTypAnutIlst[i].actulDvdTypAnut) && actulDvdTypAnutIlst[i].actulDvdTypAnut.length > 0) {
          if (arrTypLst.indexOf(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].lvyIrt) === -1) {
            arrTypLst.push(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].lvyIrt)
          }
        }
      }
      nCnt1 = arrTypLst.length

      // 테이블 헤더 설정
      this.fn_SetTableHeader(actulDvdTypAnutIlst, nCnt1)
      this.lv_HeaderItem = '1'

      // 테이블 데이터 선 설정
      let maxRowCount = 0
      let maxRowCountIndex = -1
      let seqNo = 0
      let actulDvdTypAnutIlstLen = actulDvdTypAnutIlst.length
      let tmp = new Map() // key: anutGuaPrd, value: count
      for (let i = 0; i < nCnt1; i++) {
        for (let j = 0; j < actulDvdTypAnutIlstLen / nCnt1; j++) {
          seqNo = (i * nCnt1) + j
          if (i === 0) rowData.push([])
          if (!(piCommonUtil.isObj(actulDvdTypAnutIlst[seqNo].actulDvdTypAnut) && actulDvdTypAnutIlst[seqNo].actulDvdTypAnut.length > 0 &&
          piCommonUtil.isObj(actulDvdTypAnutIlst[seqNo].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut) &&
          actulDvdTypAnutIlst[seqNo].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut.length > 0)) {
            return
          }
          let nCnt2 = actulDvdTypAnutIlst[seqNo].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut.length // 열 개수
          for (let k = 0; k < nCnt2; k++) {
            let arrData = []
            let kList = actulDvdTypAnutIlst[seqNo].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut[k] // 행별 데이터
            arrData.anutGuaPrd = actulDvdTypAnutIlst[seqNo].actulDvdTypAnut[0].anutGuaPrd
            arrData.age = kList.age
            arrData.anutPymAmt = kList.anutPymAmt
            arrData.anutAcumAmt = kList.anutAcumAmt
            rowData[j].push(arrData)
            if (!(piCommonUtil.isObj(tmp.get(arrData.anutGuaPrd)))) {
              tmp.set(arrData.anutGuaPrd, 1)
            } else {
              tmp.set(arrData.anutGuaPrd, tmp.get(arrData.anutGuaPrd) + 1)
            }
          }
          if (maxRowCount < rowData[j].length) {
            maxRowCount = rowData[j].length
            maxRowCountIndex = j
          }
        }
      }

      // 테이블 데이터 설정
      let rows = []
      let rowCount = 0
      let isFirst = true
      for (let i = 0; i < maxRowCount; i++) { // 데이터 개수(열)
        let rowItem = rowData[maxRowCountIndex]
        rows.push({})
        if (!isFirst && rowItem[i - 1].anutGuaPrd !== rowItem[i].anutGuaPrd) {
          isFirst = true
        }
        rows[rowCount]['cnt'] = tmp.get(rowItem[i].anutGuaPrd) / nCnt1
        rows[rowCount]['isFirst'] = isFirst
        isFirst = false
        rows[rowCount]['arg0'] = '실적연금형\n' + rowItem[i].anutGuaPrd + '년 지급'
        rows[rowCount]['arg1'] = rowItem[i].age + '세'
        let rowArgData = []
        let rowDataLen = rowData.length
        for (let j = 0; j < rowDataLen; j++) {
          let arrData1 = []
          let arrData2 = []
          let psgData = rowData[j][i]
          arrData1.anutAmt = (psgData.anutPymAmt === 0) ? '-' : piCommonUtil.addMoneyCommas(piCommonUtil.toChange(piCommonUtil.toChange(psgData.anutPymAmt, 'number'), 'unit')) + ' 만원' // 연금연액
          // arrData1.anutAmt = (psgData.anutPymAmt === 0) ? '-' : piCommonUtil.addMoneyCommas(this.toManWon(this.toNumber(psgData.anutPymAmt))) + ' 만원' // 연금연액
          rowArgData.push(arrData1)
          arrData2.anutAmt = (psgData.anutAcumAmt === 0) ? '-' : piCommonUtil.addMoneyCommas(piCommonUtil.toChange(piCommonUtil.toChange(psgData.anutAcumAmt, 'number'), 'unit')) + ' 만원' // 계약자적립금
          // arrData2.anutAmt = (psgData.anutAcumAmt === 0) ? '-' : piCommonUtil.addMoneyCommas(this.toManWon(this.toNumber(psgData.anutAcumAmt))) + ' 만원' // 계약자적립금
          rowArgData.push(arrData2)
        }
        rows[rowCount]['arg2'] = rowArgData
        rowCount++
      }

      this.lv_ItemsTDF = rows
    },
    /******************************************************************************
    * Function명 : fn_FilterData
    * 설명       : bind data
    ******************************************************************************/
    fn_FilterData (parentPramObj) {
      let parentPram = parentPramObj
      if (piCommonUtil.isEmpty(parentPram.anutAddmIlst) ) {
        console.log('MSPPI164D => Data 없음!')
        return
      }
      // let mainTitle = '' // 왼쪽   대타이틀
      // let mainTitle2 = '' // 왼쪽   대타이틀(병합용)
      // let cols = []
      // console.log(parentPram.anutAddmIlst[0])
      this.fn_SetInfoMessage(parentPram.zzentplDGPCd)
      let rowCount = 0
      let idx = 0
      let actulDvdTypAnutIlst = parentPram.anutAddmIlst[0].actulDvdTypAnutIlst
      // let nCnt1 = actulDvdTypAnutIlst.length
      this.fn_SetTableHeader(actulDvdTypAnutIlst)

      let ZactulDvdAnutList = []
      for (let i = 0; i < actulDvdTypAnutIlst.length; i++) { // 데이터 개수(열)
        let el = actulDvdTypAnutIlst[i]
        // 생활자금수령여부가 N(미수령)의 경우만 화면에 설정한다.
        if (el.lvngFdRcvYn !== 'N' && el.irtTypCd !== '04') continue
        // let anutFrscAmt = this.toManWon(this.toNumber(el.actulDvdTypAnut[0].anutFrscAmt)) // 첫 행에 들어가야하는 정보
        // let nCnt2 = el.actulDvdTypTotRcvAnut.length
        for (let j = 0; j < el.actulDvdTypTotRcvAnut.length; j++) { // 열 개수
          if (el.actulDvdTypTotRcvAnut[j].irtTypCd === '02' || parentPram.zzanutPymCd === '10') {
            let actulAnutList = {}
            ZactulDvdAnutList.push(actulAnutList)

            // let nCnt3 = el.actulDvdTypTotRcvAnut[j].psgPrdClbyActulDvdTypAnut.length // 각 열별 행 개수
            rowCount = 0

            // 연금연액
            let rowData = []
            for (let k = 0; k < el.actulDvdTypTotRcvAnut[j].psgPrdClbyActulDvdTypAnut.length; k++) { // 각 열별 행 개수
              let arrData = []
              let kList = el.actulDvdTypTotRcvAnut[j].psgPrdClbyActulDvdTypAnut[k] // 행별 데이터
              arrData.age = kList.age + '세'
              let anutPymAmt = piCommonUtil.toChange(kList.anutPymAmt, 'number')
              arrData.amt = anutPymAmt === 0 ? '-' : piCommonUtil.toChange(anutPymAmt, 'unit') // + ' 만원'
              // arrData.amt = this.toNumber(kList.anutPymAmt) === 0 ? '-' : this.toManWon(this.toNumber(kList.anutPymAmt)) // + ' 만원'
              rowData.push(arrData)

              rowCount++ // 조건에 해당하는 row만 count
            }
            ZactulDvdAnutList[idx].actulAnutList = rowData
            idx++
          }
        }
      }
      // console.log(ZactulDvdAnutList, '*****실적배당형연금 - ZactulDvdAnutList(1차 가공 데이터)')
      // return ZactulDvdAnutList
      let rows = []
      let rowNum = 0

      if (ZactulDvdAnutList.length > 0) {
        // this.gridData6 = rows
        // let gridData = this.gridData6
        // mainTitle(개시시점 나이)
        // mainTitle2 = mainTitle

        // 행 생성
        for (let i = 0; i < rowCount; i++) {
          let row = {}
          rows.push(row)
        }
        // rows
        let colNo = 1
        for (let i = 0; i < ZactulDvdAnutList.length; i++) {
          let arrList = ZactulDvdAnutList[i].actulAnutList
          rowNum = 0
          for (let j = 0; j < arrList.length; j++) {
            if (i === 0) {
              // rows[rowNum]['arg0'] = arrList[j].age
              rows[rowNum].age = arrList[j].age
            }
            rows[rowNum]['arg' + colNo] = piCommonUtil.addMoneyCommas(arrList[j].amt)
            rowNum++
          }
          // rowNum = 0
          // for (let j = 0; j < arrList.length; j++) {
          //   rowNum++
          // }
          colNo++
        }

        // let rowFirstUnit = {}
        // for (let i = 0; i < actulDvdTypAnutIlst.length; i++) { // 데이터 개수(열)
        //   rowFirstUnit['arg' + (i + 1)] = piCommonUtil.addMoneyCommas(this.toManWon(this.toNumber(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt))) // 첫 행에 들어가야하는 정보
        // }
        // // rowFirstUnit['arg0'] = '(' + ZactulDvdAnutList[0].actulAnutList[0].age.replace('세', '') + ')세 연금지급개시시점의\n실적배당형 연금재원'
        // rowFirstUnit.age = '(' + ZactulDvdAnutList[0].actulAnutList[0].age.replace('세', '') + ')세 연금지급개시시점의\n실적배당형 연금재원'
        // rows.unshift(rowFirstUnit)

        // header에 연금재원 넣기
        if (!this.isTDF) {
          for (let i = 0; i < actulDvdTypAnutIlst.length; i++) { // 데이터 개수(열)
            this.lv_HeaderItems[i].anutFrscAmt = piCommonUtil.addMoneyCommas(piCommonUtil.toChange(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt, 'number'))
            this.lv_HeaderItems[i].age = ZactulDvdAnutList[0].actulAnutList[0].age.replace('세', '')
          }
        }

        this.lv_Items = rows
        this.lv_HeaderItem = '1'
      }
    },
    /******************************************************************************
    * Function명 : fn_SetTableHeader
    * 설명       : Table의 헤더 부분을 동적을 구성
    ******************************************************************************/
    fn_SetTableHeader (paramObj, nCnt1) {
      // let nCnt1 =  // 데이터 개수(열)
      // let rightTitle = [] // 오른쪽 타이틀
      let colCount = 0 // colCount = 출력 리스트 갯수(세로)
      if (this.isTDF) {
        let actulDvdTypAnutIlst = paramObj
        this.lv_HeaderItems.push({ index: colCount, key: `${colCount + 1}`, age: `${actulDvdTypAnutIlst[0].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut[0].age}`, label: ''})
        this.lv_HeaderItemsSub.push({index: colCount, key: `${colCount + 1}`, label0: '구분', label1: '나이(세)'})
        // this.lv_HeaderItems.push({ index: colCount, key: `${colCount + 1}`, label: '(' + actulDvdTypAnutIlst[0].actulDvdTypAnut[0].psgPrdClbyActulDvdTypAnut[0].age + '세)연금지급개시시점의' + '<br/>실적연금 기준금액'})
        // this.lv_HeaderItemsSub.push({index: colCount, key: `${colCount + 1}`, label0: '구분', label1: '나이(세)'})

        let actulDvdTypAnutIlstLen = actulDvdTypAnutIlst.length
        for (let i = 0; i < actulDvdTypAnutIlstLen / nCnt1; i++) {
          colCount++ // 조건에 해당하는 column만 count
          // let lvyIrt = this.toPercent(actulDvdTypAnutIlst[i].lvyIrt, 2)
          let lvyIrt = piCommonUtil.toChange(actulDvdTypAnutIlst[i].lvyIrt, 'percent', 3)
          // let lvyIrt = this.toPercent(actulDvdTypAnutIlst[i].lvyIrt, 3)
          let anutFrscAmt = piCommonUtil.addMoneyCommas(piCommonUtil.toChange(piCommonUtil.toChange(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt, 'number'), 'unit'))
          // let anutFrscAmt = piCommonUtil.addMoneyCommas(this.toManWon(this.toNumber(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt)))
          this.lv_HeaderItems.push({index: colCount, key: `${colCount + 1}`, label: lvyIrt + '% 가정', anutFrscAmt: anutFrscAmt})
          // this.lv_HeaderItems.push({index: colCount, key: `${colCount + 1}`, label: '투자수익률 ' + lvyIrt + '% 가정' + '<br/>(' + anutFrscAmt + ' 만원)'})
          // this.lv_HeaderItems.push({key: colCount, label: '투자수익률 ' + lvyIrt + '% 가정' + '<br/>(' + piCommonUtil.addMoneyCommas(this.toManWon(this.toNumber(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt))) + ' 만원)'})
          this.lv_HeaderItemsSub.push({index: colCount, key: `${colCount + 1}`, label0: '연금연액', label1: '계약자적립금'})
        }
      } else {
        let actulDvdTypAnutIlst = paramObj
        for (let i = 0; i < actulDvdTypAnutIlst.length; i++) {
          if (actulDvdTypAnutIlst[i].lvngFdRcvYn !== 'N' && actulDvdTypAnutIlst[i].irtTypCd !== '04') continue
          // let lvyIrt = this.toPercent(actulDvdTypAnutIlst[i].lvyIrt, 2)
          let lvyIrt = piCommonUtil.toChange(actulDvdTypAnutIlst[i].lvyIrt, 'percent', 3)
          // let lvyIrt = this.toPercent(actulDvdTypAnutIlst[i].lvyIrt, 3)
          // let anutFrscAmt = this.toManWon(this.toNumber(actulDvdTypAnutIlst[i].actulDvdTypAnut[0].anutFrscAmt)) => content로 들어감
          // rightTitle[colCount] = '투자수익률 ' + lvyIrt + '% 가정 \n (' + anutFrscAmt + ' 만원)'
          // rightTitle[colCount] = lvyIrt + '% 가정 \n (만원)'
          // this.lv_HeaderItems.push({key: colCount, label: rightTitle[colCount]})
          this.lv_HeaderItems.push({index: colCount, key: `${colCount + 1}`, label: lvyIrt + '% 가정', age: '', anutFrscAmt: ''})
          // this.lv_HeaderItems.push({key: colCount, label: lvyIrt + '% 가정 \n (만원)'})
          // console.log(this.lv_HeaderItems, '*****실적배당형연금 - lv_HeaderItems')
          colCount++ // 조건에 해당하는 column만 count
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_SetInfoMessage
    * 설명       : 알림메시지 세팅
    ******************************************************************************/
    fn_SetInfoMessage (zzentplDGPCd) {
      // 문구 출력
      if (zzentplDGPCd === '01') {
        this.lv_InfoMessage = '실적배당형연금의 최대 연금지급기간은 100세입니다.'
      } else if (zzentplDGPCd === '02') {
        this.lv_InfoMessage = '실적배당형연금의 최대 연금지급기간은 종신이나, 100세까지만 예시됩니다.'
      } else {
        this.lv_InfoMessage = ''
      }
    },
  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>