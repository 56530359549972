/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI161D
 * 화면설명: 결과보기(스마트 리포트) > 기본정보
 * 접근권한: 
 * 작 성 일: 2022.02.01
 * 작 성 자: ---
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-pi-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="" direction="row" class="gray-box mb0 pa2024">
          <div class="fexTy3">
            <span class="fs16rem">{{this.parentParam.smtotPrmNm}}</span>
            <!-- <span class="fs16rem">합계보험료</span> -->
            <b class="fs18rem txt-right">{{$bizUtil.numberWithCommasCurr(lv_SmtotPrm, currencyId, 'Y', 'Y', 3)}}</b>
          </div>
          <div class="fexTy3 mt10">
            <span class="fs16rem">{{this.parentParam.rlpadPrmNm}}</span>
            <!-- <span class="fs16rem">실납입보험료</span> -->
            <b class="fs18rem txt-right">{{$bizUtil.numberWithCommasCurr(lv_RlpadPrm, currencyId, 'Y', 'Y', 3)}}</b>
          </div>
        </ur-box-container>
      </ur-box-container>      
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <mo-list :list-data="lv_Items">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.name}}</span>
                  <mo-badge class="badge-sample-badge" :class="{'lightblue' : item.relative === '주피', 'lightblue4' : item.relative === '종피'}" text="" shape="status">{{item.relative}}</mo-badge>
                </div>
                <div class="list-item__contents__info">
                  <span><span class="fs14rem">보험기간</span><span class="ml10 crTy-bk1">{{item.inprd}}</span></span><em>|</em><span><span class="fs14rem">납입기간</span><span class="ml10 crTy-bk1">{{item.pmprd}}</span></span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="full fs14rem">가입금액</span>
                  <span class="full fwb crTy-bk1 txt-right">{{$bizUtil.numberWithCommasCurr(item.covInsAmt, currencyId, 'N', 'Y', 3)}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="full fs14rem">보험료</span>
                  <span class="full fwb crTy-blue3 txt-right">{{$bizUtil.numberWithCommasCurr(item.covPrm, currencyId, 'Y', 'Y', 3)}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>        
      </ur-box-container>
    </ur-box-container>
  </ur-box-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI161D', 
  screenId: 'MSPPI161D',
  props: {
    parentParam: Object
  }, // parents data
  data() {
    return {
      lv_ShowData: true,
      lv_SmtotPrm: 0, // 합계보험료
      lv_RlpadPrm: 0, // 실납입보험료
      /* // lv_Items 요소
      {
        relative: '주피', // 관계
        name: '연금보험', // 보험명
        covInsAmt: '10,000', // 가입금액
        inprd: '3년', // 보험기간
        pmprd: '10년', // 납입기간
        covPrm: '10,000' // 보험료
      } */
      lv_Items: [],
      currencyId: '' // 202005 외화보험 통화키
    }
  },
  /************************************************************************************************
   * Lyfe Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    if (this.parentParam !== null) {
      console.log('MSPPI161D')
      this.fn_MakeGrid(this.parentParam)
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_MakeGrid
    * 설명       : bind data
    ******************************************************************************/
    fn_MakeGrid (parentPramObj) {
      let lv_Vm = this
      let parentPram = parentPramObj
      this.lv_SmtotPrm = parentPram.smtotPrm
      this.lv_RlpadPrm = parentPram.rlpadPrm
      let gridData1 = []

      if (parentPram.basicInfo.length > 0) {
        // 202005 외화보험 통화키
        this.currencyId = parentPram.basicInfo[0].currencyId
        for (let k = 0; k < parentPram.basicInfo.length; k++) {
          let el = parentPram.basicInfo[k]
          let row = {}
          try {
            if (el.abdacov.covAbdasubjct[0].zzcontvInsrdCd === '23') {
              row.relative = '종피'
              row.name = el.abdacov.ztpqlCboextAddmAbdacov.zaInsrNm
            } else if (el.abdacov.covAbdasubjct[0].zzcontvInsrdCd === '24') {
              // to-be 자녀 X
              // if (el.abdacov.covAbdasubjct[0].zzchldSnoVl === 2) {
              //   row.relative = '자녀2'
              //   row.name = el.abdacov.ztpqlCboextAddmAbdacov.zaInsrNm
              // } else if (el.abdacov.covAbdasubjct[0].zzchldSnoVl === 3) {
              //   row.relative = '자녀3'
              //   row.name = el.abdacov.ztpqlCboextAddmAbdacov.zaInsrNm
              // } else {
              //   row.relative = '자녀1'
              //   row.name = el.abdacov.ztpqlCboextAddmAbdacov.zaInsrNm
              // }
            } else {
              row.relative = '주피'
              row.name = el.abdacov.ztpqlCboextAddmAbdacov.zaInsrNm
            }

            row.covInsAmt = (el.covInsAmt)
            if (el.unitNm === '만원') {
              row.covInsAmt *= 10000
            } else if (el.unitNm === '천원') {
              row.covInsAmt *= 1000
            }
            // to-be
            // row.covInsAmt = lv_Vm.$bizUtil.numberWithCommasCurr(row.covInsAmt, lv_Vm.currencyId, 'N', 'Y', 3)

            if (el.abdacov.aldacov.zzinprdTypVl === 999) {
              row.inprd = '종신'
            } else {
              if (el.abdacov.aldacov.zzinprdTypCd === '01') {
                row.inprd = el.abdacov.aldacov.zzinprdTypVl + '년'
              } else {
                row.inprd = el.abdacov.aldacov.zzinprdTypVl + '세'
              }
            }
            if (el.abdacov.covAbdaprem[0].covAldaprem.zzpmprdTypVl === 0) {
              row.pmprd = '일시납'
            } else {
              if (el.abdacov.covAbdaprem[0].covAldaprem.zzpmprdTypCd === '01') {
                row.pmprd = el.abdacov.covAbdaprem[0].covAldaprem.zzpmprdTypVl + '년'
              } else {
                row.pmprd = el.abdacov.covAbdaprem[0].covAldaprem.zzpmprdTypVl + '세'
              }
            }

            row.covPrm = (el.covPrm)
            // to-be
            // row.covPrm = lv_Vm.$bizUtil.numberWithCommasCurr(row.covPrm, lv_Vm.currencyId, 'Y', 'Y', 3)
          } catch (e) {
            console.log('기본정보 - ' + e)
          }
          gridData1.push(row)
        }
      }
      this.lv_Items = gridData1
      // console.log('[MSPPI161D] ====> ', this.lv_Items)
    }
  }
}
</script>
